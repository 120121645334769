import React, { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import frLocale from 'date-fns/locale/fr';
import enLocale from 'date-fns/locale/en-US';
import PublicApp from '../../shared/PublicApp';
import FrontHeader from '../../shared/FrontHeader';
import FrontFooter from '../../shared/FrontFooter';
import StepperButtons from '../../shared/StepperButtons';
import ProductAdd from '../../shared/funeral/ProductAdd';
import ShoppingCart from '../../shared/funeral/ShoppingCart';
import PublicDataTable from '../../shared/PublicDataTable';
import {
  ListItemText,
  Container,
  Box,
  Stack,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
  ListItem,
  List,
  Grid,
  TextField,
  Switch
} from '@mui/material';

import { useTranslation } from 'react-i18next';

const fielsPerStep = [['id'], [], ['funeral_decorations_attributes']];

Gifts.propTypes = {
  flash: PropTypes.array,
  errors: PropTypes.array,
  locale: PropTypes.string,
  products: PropTypes.array,
  funerals: PropTypes.array,
  current_user: PropTypes.object,
  authenticity_token: PropTypes.string,
  signed_in: PropTypes.bool
};

const localeMap = {
  en: enLocale,
  fr: frLocale,
  rw: frLocale
};

export default function Gifts({
  flash,
  errors,
  locale,
  current_user,
  signed_in,
  products,
  funerals,
  authenticity_token
}) {
  const { t, i18n } = useTranslation();

  const steps = [t('shop.step_1'), t('shop.step_2'), t('shop.step_3')];

  const handleNext = () => {
    setFieldValue({
      activeStep: funeral.activeStep + 1
    });
  };

  const handleBack = () => {
    setFieldValue({
      activeStep: funeral.activeStep - 1
    });
  };

  const [funeral, dispatch] = useReducer(
    (funeral, action) => {
      switch (action.type) {
        case 'SET_VALUE':
          return { ...funeral, ...action.payload };
        default:
          return funeral;
      }
    },
    // {
    // id: "",
    // grave_id: "",
    // first_name: "",
    // last_name: "",
    // other_names: "",
    // birth_date: null,
    // death_date: null,
    // funeral_decorations_attributes: [],
    // activeStep: 0,
    // checkOutTotal: 0,
    // }
    {
      id: '',
      grave_id: '',
      first_name: '',
      last_name: '',
      other_names: '',
      birth_date: null,
      death_date: null,
      funeral_messages_attributes: [
        {
          post: '',
          user_id: current_user.id,
          consent: false
        }
      ],
      funeral_decorations_attributes: [],
      activeStep: 0,
      checkOutTotal: 0
    }
  );

  const setFieldValue = (fieldValue) => {
    dispatch({ type: 'SET_VALUE', payload: fieldValue });
  };

  const setSelectedFuneral = (id) => {
    const funer = funerals.find((f) => String(f.id) === String(id));
    setFieldValue({
      ...funer,
      grave_id: funer.decorations.find((g) => g.kind === 'grave').id,
      funeral_decorations_attributes: funer.decorations
    });
  };

  const columns = [
    {
      name: 'hiden_id',
      label: 'Hidden Id',
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          style: { fontSize: '0px' }
        }),
        setCellProps: () => ({
          style: { fontSize: '0px' }
        })
      }
    },
    {
      name: 'hiden_names',
      label: 'Hidden Names',
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          style: { fontSize: '0px' }
        }),
        setCellProps: () => ({
          style: { fontSize: '0px' }
        })
      }
    },
    {
      name: 'name',
      label: (
        <Typography component="b" variant="subtitle1">
          {t('shop.victimNames')}
        </Typography>
      ),
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'dates',
      label: (
        <Typography component="b" variant="subtitle1">
          {t('shop.genderAndDated')}
        </Typography>
      ),
      options: {
        filter: false,
        sort: false
      }
    },
    {
      name: 'places',
      label: (
        <Typography component="b" variant="subtitle1">
          {t('shop.victimPlaces')}
        </Typography>
      ),
      options: {
        filter: false,
        sort: false
      }
    }
  ];

  useEffect(() => {
    i18n.changeLanguage(locale);
  }, [locale]); // eslint-disable-line

  return (
    <PublicApp
      flash={
        flash !== null && flash !== undefined && flash.filter((f) => f !== null)
      }
      errors={errors}
      locale={locale}
      children={
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh'
          }}
        >
          <FrontHeader
            showLogo={true}
            signedIn={signed_in}
            currentUser={current_user}
            authenticityToken={authenticity_token}
            locale={locale}
          />
          <Container
            disableGutters
            maxWidth="lg"
            component="main"
            sx={{ pt: 4, pb: 6 }}
          >
            <Typography
              variant="h4"
              gutterBottom
              component="div"
              align="center"
            >
              {t('shop.title')}
            </Typography>

            <Box sx={{ width: '100%' }}>
              <Stepper activeStep={funeral.activeStep}>
                {steps.map((label) => {
                  const stepProps = {};
                  const labelProps = {};
                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
              {funeral.activeStep === steps.length ? (
                <ShoppingCart
                  sponsored={true}
                  newShoppingCart={false}
                  handleBack={handleBack}
                  authenticityToken={authenticity_token}
                  formHiddenFields={[]}
                  formFields={[
                    'funeral_messages_attributes',
                    'funeral_decorations_attributes'
                  ]}
                  displayFields={[
                    {
                      label: t('funeral.firstName'),
                      field: 'first_name'
                    },
                    {
                      label: t('funeral.lastName'),
                      field: 'last_name'
                    },
                    {
                      label: t('funeral.surName'),
                      field: 'other_names'
                    },
                    { label: t('funeral.gender'), field: 'gender' }
                  ]}
                  products={products}
                  funeral={funeral}
                  locale={locale}
                />
              ) : (
                <React.Fragment>
                  <StepperButtons
                    activeStep={funeral.activeStep}
                    handleBack={handleBack}
                    handleNext={handleNext}
                    steps={steps}
                    fields={fielsPerStep[funeral.activeStep]}
                    values={funeral}
                  />
                  {funeral.activeStep === 0 && (
                    <Stack
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={2}
                    >
                      <Typography component="h4" variant="h6" color="primary">
                        {t('shop.victimListTitle')}
                      </Typography>
                      <RadioGroup
                        aria-labelledby="controlled-funeral-selector"
                        name="controlled-funeral-selector"
                        value={funeral.id}
                        // onChange={(e) => {
                        //   setSelectedFuneral(e.target.value);
                        // }}
                      >
                        <PublicDataTable
                          title={t('shop.victimListTitle')}
                          searchPlaceholder={t('shop.searchPlaceholder')}
                          setSelectedFuneral={setSelectedFuneral}
                          data={funerals.map((row) => ({
                            hiden_id: row.id,
                            hiden_names:
                              row.first_name +
                              ' ' +
                              row.last_name +
                              ' ' +
                              row.other_names +
                              ' ' +
                              row.prefecture.name +
                              ' ' +
                              t('funeral.' + row.gender),
                            name: (
                              <FormControlLabel
                                key={'grave-' + row.id}
                                labelPlacement="end"
                                value={row.id}
                                control={<Radio />}
                                label={
                                  <List dense component="div" disablePadding>
                                    <ListItem>
                                      <ListItemText
                                        primary={
                                          row.first_name +
                                          (row.other_names !== null &&
                                            ' ' + row.other_names)
                                        }
                                        secondary={
                                          row.first_name !== undefined && (
                                            <Typography
                                              variant="caption"
                                              component="span"
                                              color="textSecondary"
                                            >
                                              {row.last_name}
                                            </Typography>
                                          )
                                        }
                                      />
                                    </ListItem>
                                  </List>
                                }
                              />
                            ),
                            dates: (
                              <List dense component="div" disablePadding>
                                <ListItem>
                                  <ListItemText
                                    primary={
                                      <Typography
                                        variant="subtitle2"
                                        component="div"
                                      >
                                        {format(
                                          new Date(row.birth_date),
                                          'd MMMM yyyy',
                                          {
                                            locale: localeMap[locale]
                                          }
                                        ) +
                                          ' -- ✝ ' +
                                          format(
                                            new Date(row.death_date),
                                            'd MMMM yyyy',
                                            {
                                              locale: localeMap[locale]
                                            }
                                          )}
                                      </Typography>
                                    }
                                    secondary={
                                      <Typography
                                        variant="caption"
                                        component="i"
                                        color="textSecondary"
                                      >
                                        {t('funeral.' + row.gender)}
                                      </Typography>
                                    }
                                  />
                                </ListItem>
                              </List>
                            ),
                            places: (
                              <List dense component="div" disablePadding>
                                <ListItem>
                                  <ListItemText
                                    primary={
                                      <Typography
                                        variant="subtitle2"
                                        component="div"
                                      >
                                        {row.prefecture.name}
                                      </Typography>
                                    }
                                    secondary={
                                      <Typography
                                        variant="caption"
                                        component="i"
                                        color="textSecondary"
                                      >
                                        {row.country.name}
                                      </Typography>
                                    }
                                  />
                                </ListItem>
                              </List>
                            )
                          }))}
                          columns={columns}
                          options={{}}
                        />
                      </RadioGroup>
                    </Stack>
                  )}
                  {funeral.activeStep === 1 && (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        pt: 2,
                        px: 8
                      }}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            required
                            id="memory"
                            label={t('funeral.message')}
                            multiline
                            rows={5}
                            variant="filled"
                            inputProps={{
                              name: 'funeral[message]'
                            }}
                            value={funeral.funeral_messages_attributes[0].post}
                            onChange={(e) =>
                              setFieldValue({
                                funeral_messages_attributes: [
                                  {
                                    post: e.target.value,
                                    user_id: current_user.id,
                                    consent:
                                      funeral.funeral_messages_attributes[0]
                                        .consent
                                  }
                                ]
                              })
                            }
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormControlLabel
                            labelPlacement="end"
                            control={
                              <Switch
                                size="small"
                                checked={
                                  funeral.funeral_messages_attributes[0].consent
                                }
                                onChange={(event) => {
                                  setFieldValue({
                                    funeral_messages_attributes: [
                                      {
                                        post: funeral
                                          .funeral_messages_attributes[0].post,
                                        user_id: current_user.id,
                                        consent: event.target.checked
                                      }
                                    ]
                                  });
                                }}
                              />
                            }
                            label={
                              <Typography variant="caption">
                                {t('funeral.message_consent')}
                              </Typography>
                            }
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                  {funeral.activeStep === 2 && (
                    <ProductAdd
                      products={products}
                      funeral={funeral}
                      locale={locale}
                      setFieldValue={setFieldValue}
                      currentUser={current_user}
                    />
                  )}
                  <StepperButtons
                    activeStep={funeral.activeStep}
                    handleBack={handleBack}
                    handleNext={handleNext}
                    steps={steps}
                    fields={fielsPerStep[funeral.activeStep]}
                    values={funeral}
                  />
                </React.Fragment>
              )}
            </Box>
          </Container>
          <FrontFooter
            currentUser={current_user}
            authenticityToken={authenticity_token}
            locale={locale}
          />
        </Box>
      }
    />
  );
}
