import React from 'react';
import PropTypes from 'prop-types';
import { format, isBefore, isSameDay } from 'date-fns';

import { Grid, TextField, MenuItem, Autocomplete } from '@mui/material';

import DatePicker from '@mui/lab/DatePicker';

VictimIdentificationForm.propTypes = {
  funeral: PropTypes.object,
  prefectures: PropTypes.array,
  countries: PropTypes.array,
  setFieldValue: PropTypes.func,
  t: PropTypes.func
};

export default function VictimIdentificationForm({
  funeral,
  prefectures,
  countries,
  setFieldValue,
  t
}) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          required
          size="small"
          fullWidth
          variant="filled"
          id="first_name"
          label={t('funeral.firstName')}
          name="first_name"
          inputProps={{
            name: 'funeral[first_name]'
          }}
          value={funeral.first_name}
          onChange={(e) =>
            setFieldValue({
              first_name: e.target.value
            })
          }
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          size="small"
          fullWidth
          variant="filled"
          id="last_name"
          label={t('funeral.lastName')}
          inputProps={{ name: 'funeral[last_name]' }}
          value={funeral.last_name}
          onChange={(e) =>
            setFieldValue({
              last_name: e.target.value
            })
          }
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          size="small"
          fullWidth
          variant="filled"
          id="other_names"
          label={t('funeral.surName')}
          inputProps={{ name: 'funeral[other_names]' }}
          value={funeral.other_names}
          onChange={(e) =>
            setFieldValue({
              other_names: e.target.value
            })
          }
        />
      </Grid>
      <Grid item xs={6}>
        <DatePicker
          disableFuture
          label={t('funeral.dob')}
          inputFormat="dd MMMM yyyy"
          openTo="year"
          views={['year', 'month', 'day']}
          minDate={new Date('1900-01-01')}
          // maxDate={new Date("2003-12-31")}
          value={funeral.birth_date}
          onChange={(birthDate) => {
            setFieldValue({
              birth_date: format(new Date(birthDate), 'yyyy-MM-dd'),
              death_date: null
            });
          }}
          renderInput={(params) => (
            <TextField
              required
              size="small"
              fullWidth
              variant="filled"
              {...{
                ...params,
                inputProps: {
                  ...params.inputProps,
                  name: 'funeral[birth_date]'
                }
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <DatePicker
          disableFuture
          label={t('funeral.dod')}
          inputFormat="dd MMMM yyyy"
          openTo="year"
          views={['year', 'month', 'day']}
          minDate={
            isBefore(new Date('1990-10-01'), new Date(funeral.birth_date)) ||
            isSameDay(new Date('1990-10-01'), new Date(funeral.birth_date))
              ? new Date(funeral.birth_date)
              : new Date('1990-10-01')
          }
          //  maxDate={new Date("2003-12-31")}
          value={funeral.death_date}
          onChange={(deathDate) => {
            const birthDate = new Date(funeral.birth_date);
            const newDeathDate = new Date(deathDate);

            if (
              isBefore(birthDate, newDeathDate) ||
              isSameDay(birthDate, newDeathDate)
            ) {
              setFieldValue({
                death_date: format(new Date(deathDate), 'yyyy-MM-dd')
              });
            } else {
              setFieldValue({
                death_date: null
              });
            }
          }}
          renderInput={(params) => (
            <TextField
              required
              size="small"
              fullWidth
              variant="filled"
              {...{
                ...params,
                inputProps: {
                  ...params.inputProps,
                  name: 'funeral[death_date]'
                }
              }}
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          required
          select
          size="small"
          fullWidth
          variant="filled"
          id="gender"
          label={t('funeral.gender')}
          inputProps={{ name: 'funeral[gender]' }}
          value={funeral.gender}
          onChange={(e) =>
            setFieldValue({
              gender: e.target.value
            })
          }
        >
          {[
            { name: t('funeral.male'), id: 'male' },
            { name: t('funeral.female'), id: 'female' },
            { name: t('funeral.other'), id: 'other' }
          ].map((option) => (
            <MenuItem key={'gender-' + option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12}>
        <fieldset
          style={{
            width: '100%',
            marginBottom: 10,
            padding: 10,
            border: '1px solid rgba(255, 255, 255, 0.3)',
            borderRadius: 5
          }}
        >
          <legend
            style={{
              color: 'rgba(255, 255, 255, 0.7)'
            }}
          >
            {t('funeral.placeFieldSets')}
          </legend>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                required
                select
                size="small"
                fullWidth
                variant="filled"
                id="prefecture_id"
                label={t('funeral.prefecture')}
                inputProps={{
                  name: 'funeral[prefecture_id]'
                }}
                value={funeral.prefecture_id}
                onChange={(e) =>
                  setFieldValue({
                    prefecture_id: e.target.value
                  })
                }
              >
                {prefectures !== undefined &&
                  prefectures.map((prefecture) => (
                    <MenuItem key={prefecture.id} value={prefecture.id}>
                      {prefecture.name}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                size="small"
                fullWidth
                variant="filled"
                id="commune"
                label={t('funeral.commune')}
                inputProps={{
                  name: 'funeral[commune]'
                }}
                value={funeral.commune}
                onChange={(e) =>
                  setFieldValue({
                    commune: e.target.value
                  })
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                size="small"
                fullWidth
                variant="filled"
                id="secteur"
                label={t('funeral.sector')}
                inputProps={{
                  name: 'funeral[secteur]'
                }}
                value={funeral.secteur}
                onChange={(e) =>
                  setFieldValue({
                    secteur: e.target.value
                  })
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                size="small"
                fullWidth
                variant="filled"
                id="cellule"
                label={t('funeral.cell')}
                inputProps={{
                  name: 'funeral[cellule]'
                }}
                value={funeral.cellule}
                onChange={(e) =>
                  setFieldValue({
                    cellule: e.target.value
                  })
                }
              />
            </Grid>
          </Grid>
        </fieldset>
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          freeSolo
          required
          fullWidth
          size="small"
          id="country_id"
          options={countries}
          getOptionLabel={(option) => option.name}
          onChange={(event, newValue, reason) => {
            if (reason === 'clear') {
              setFieldValue({
                country_id: '',
                country_name: ''
              });
            } else {
              setFieldValue({
                country_id: newValue === null ? null : newValue.id,
                country_name: newValue === null ? null : newValue.name
              });
            }
          }}
          onInputChange={(event, value, reason) => {
            if (reason === 'clear') {
              setFieldValue({
                country_id: '',
                country_name: ''
              });
            } else {
              setFieldValue({
                country_id: 9999,
                country_name: value
              });
            }
          }}
          renderInput={(params) => (
            <TextField
              size="small"
              variant="filled"
              label={t('funeral.cod')}
              {...{
                ...params,
                inputProps: {
                  ...params.inputProps,
                  name: 'funeral[country_id]'
                }
              }}
            />
          )}
        />
      </Grid>
    </Grid>
  );
}
