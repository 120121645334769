import React from 'react';
import PropTypes from 'prop-types';
import { Box, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import AnimatedProduct from './AnimatedProduct';
import { format } from 'date-fns';
import frLocale from 'date-fns/locale/fr';
import enLocale from 'date-fns/locale/en-US';
import useFitText from 'use-fit-text';

const GraveContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  flexDirection: 'column',
  position: 'relative'
});

const GraveBox = styled(Box)({
  position: 'relative'
});

const ProductsBox = styled(Box)({
  position: 'relative',
  bottom: '95px',
  minHeight: '100px',
  maxHeight: '200px'
});

AnnotatedGrave.propTypes = {
  grave: PropTypes.object,
  decorations: PropTypes.array,
  products: PropTypes.array,
  funeral: PropTypes.object,
  dropFlowers: PropTypes.bool,
  showFinal: PropTypes.bool,
  locale: PropTypes.string,
  coordinates: PropTypes.array
};
TextLabel.propTypes = {
  label: PropTypes.string,
  width: PropTypes.number,
  option: PropTypes.object
};

const localeMap = {
  en: enLocale,
  fr: frLocale,
  rw: frLocale
};

function TextLabel({ label, width, option }) {
  const { fontSize, ref } = useFitText(option);

  return (
    <div ref={ref} style={{ fontSize, width: width, textAlign: 'center' }}>
      {label}
    </div>
  );
}
export default function AnnotatedGrave({
  grave,
  decorations,
  products,
  funeral,
  locale,
  showFinal,
  dropFlowers
}) {
  return (
    <GraveContainer>
      <GraveBox
        sx={{
          width: grave.coordinates[1],
          maxWidth: grave.coordinates[1],
          height: grave.coordinates[0],
          maxHeight: grave.coordinates[0],
          pr: 9,
          pl: 9,
          backgroundImage: `url(${
            '/products/' +
            (showFinal === true ? grave.final_picture : grave.picture)
          })`,
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat'
        }}
      >
        {funeral.grave_id !== '' &&
          funeral.first_name !== '' &&
          funeral.first_name !== undefined && (
            <div
              style={{
                textAlign: 'center',
                fontSize: '16px',
                padding: '4px 6px 4px 6px',
                border: '2px solid #00000040',
                position: 'absolute',
                backgroundColor: '#00000070',
                borderRadius: 4,
                top: grave.coordinates[2],
                left: grave.coordinates[3],
                width: grave.coordinates[4],
                height: grave.coordinates[5]
              }}
            >
              <p>
                <TextLabel
                  label={funeral.first_name}
                  width={grave.coordinates[4] - 10}
                />
                <TextLabel
                  label={funeral.last_name}
                  width={grave.coordinates[4] - 10}
                />
              </p>

              {funeral.birth_date !== null && funeral.birth_date !== undefined && (
                <p>
                  <TextLabel
                    label={format(new Date(funeral.birth_date), 'd MMMM yyyy', {
                      locale: localeMap[locale]
                    })}
                    width={grave.coordinates[4] - 10}
                    option={{ minFontSize: 4, maxFontSize: 80 }}
                  />
                  <TextLabel
                    label={
                      funeral.death_date !== null &&
                      '✝ ' +
                        format(new Date(funeral.death_date), 'd MMMM yyyy', {
                          locale: localeMap[locale]
                        })
                    }
                    width={grave.coordinates[4] - 10}
                    option={{ minFontSize: 4, maxFontSize: 75 }}
                  />
                </p>
              )}
            </div>
          )}
      </GraveBox>
      <ProductsBox
        sx={{
          width: grave.coordinates[1] * 0.75,
          maxWidth: grave.coordinates[1] * 0.75
        }}
      >
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="flex-end"
          spacing={0}
          sx={{
            height: '100%',
            alignSelf: 'flex-end',
            flexWrap: 'wrap'
          }}
        >
          {decorations !== undefined &&
            decorations.length > 0 &&
            decorations.map((decoration, decorationIndex) => (
              <AnimatedProduct
                key={'animated-product-' + decorationIndex}
                dropFlowers={dropFlowers}
                productImagePath={
                  products.find(
                    (product) =>
                      String(product.id) === String(decoration.product_id)
                  )?.picture
                }
              />
            ))}
        </Stack>
      </ProductsBox>
    </GraveContainer>
  );
}
