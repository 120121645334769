import React, { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import PublicApp from '../../shared/PublicApp';
import FrontHeader from '../../shared/FrontHeader';
import FrontFooter from '../../shared/FrontFooter';
import FrontButton from '../../shared/FrontButton';
import {
  ListItemText,
  Container,
  Box,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  TextField,
  FormLabel,
  FormControl
} from '@mui/material';

import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import { useTranslation } from 'react-i18next';
import { hrefLink } from '../../../utils/core';

// Thank you for your order.
// Your order number is #2001539. We have emailed your order confirmation, and will send you an update when your order has shipped.

Funeral.propTypes = {
  flash: PropTypes.array,
  errors: PropTypes.array,
  locale: PropTypes.string,
  authenticity_token: PropTypes.string,
  current_user: PropTypes.object,
  signed_in: PropTypes.bool
};

export default function Funeral({
  flash,
  errors,
  locale,
  authenticity_token,
  current_user,
  signed_in
}) {
  const { t, i18n } = useTranslation();

  const [choice, dispatch] = useReducer(
    (choice, action) => {
      switch (action.type) {
        case 'SET_VALUE':
          return { ...choice, ...action.payload };
        default:
          return choice;
      }
    },
    // {
    //   kind: 'promoted',
    //   promo_code: 'D46D702F'
    // }
    {
      kind: '',
      promo_code: ''
    }
  );

  const setFieldValue = (fieldValue) => {
    dispatch({ type: 'SET_VALUE', payload: fieldValue });
  };

  useEffect(() => {
    i18n.changeLanguage(locale);
  }, [locale]); // eslint-disable-line

  return (
    <PublicApp
      flash={
        flash !== null && flash !== undefined && flash.filter((f) => f !== null)
      }
      errors={errors}
      locale={locale}
      children={
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh'
          }}
        >
          <FrontHeader
            showLogo={true}
            signedIn={signed_in}
            currentUser={current_user}
            authenticityToken={authenticity_token}
            locale={locale}
          />
          <Container
            disableGutters
            maxWidth="lg"
            component="main"
            sx={{ pt: 4, pb: 6 }}
          >
            <Typography
              variant="h4"
              gutterBottom
              component="div"
              align="center"
            >
              {t('funeral.title')}
            </Typography>

            <form
              role="form"
              acceptCharset="UTF-8"
              action={hrefLink('/funerals/initiate')}
              method="post"
            >
              <input
                type="hidden"
                name="authenticity_token"
                value={authenticity_token}
              />

              <input type="hidden" name={'funeral_kind'} value={choice.kind} />

              <input type="hidden" name="_method" value="POST" />
              <Box
                sx={{
                  pt: 4,
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <FormControl sx={{ m: 3 }} variant="standard">
                  <FormLabel id="funeral-type-selector">
                    {t('funeral.kind')}
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="funeral-type-selector"
                    name="funeral_kind"
                    value={choice.kind}
                    onChange={(e) =>
                      setFieldValue({
                        kind: e.target.value
                      })
                    }
                  >
                    <FormControlLabel
                      value="promoted"
                      control={<Radio />}
                      label={
                        <ListItemText
                          primary={t('funeral.individualPromotedKind')}
                          secondary={
                            <Typography
                              variant="caption"
                              display="block"
                              color="text.secondary"
                              gutterBottom
                            >
                              {t('funeral.individualPromotedKindDescription')}
                            </Typography>
                          }
                        />
                      }
                    />
                    {choice.kind === 'promoted' && (
                      <TextField
                        required
                        autoFocus
                        size="medium"
                        fullWidth
                        variant="filled"
                        id="promo_code"
                        label={t('funeral.individualPromoCode')}
                        name="promo_code"
                        inputProps={{
                          name: 'promo_code'
                        }}
                        value={choice.promo_code}
                        onChange={(e) =>
                          setFieldValue({
                            promo_code: e.target.value.toUpperCase()
                          })
                        }
                        sx={{ ml: 4, maxWidth: 400 }}
                      />
                    )}
                    <FormControlLabel
                      value="individual"
                      control={<Radio />}
                      label={
                        <ListItemText
                          primary={t('funeral.individualKind')}
                          secondary={
                            <Typography
                              variant="caption"
                              display="block"
                              color="text.secondary"
                              gutterBottom
                            >
                              {t('funeral.individualKindDescription')}
                            </Typography>
                          }
                        />
                      }
                    />
                    <FormControlLabel
                      value="group"
                      disabled
                      control={<Radio />}
                      label={
                        <ListItemText
                          primary={t('funeral.groupKind')}
                          secondary={
                            <>
                              <Typography
                                variant="caption"
                                display="block"
                                color="text.secondary"
                                gutterBottom
                              >
                                {t('funeral.groupKindDescription')}
                              </Typography>
                              <Typography
                                variant="caption"
                                display="block"
                                color="text.secondary"
                                gutterBottom
                              >
                                {t('funeral.groupKindApology')}
                              </Typography>
                            </>
                          }
                        />
                      }
                    />
                  </RadioGroup>
                </FormControl>
                <FrontButton
                  type="submit"
                  disabled={choice.kind === ''}
                  children={
                    <Typography variant="h5" component="div">
                      {t('home.funeralKindChoice')}
                    </Typography>
                  }
                  size="medium"
                  endIcon={<ArrowForwardOutlinedIcon />}
                />
              </Box>
            </form>
          </Container>
          <FrontFooter
            currentUser={current_user}
            authenticityToken={authenticity_token}
            locale={locale}
          />
        </Box>
      }
    />
  );
}
