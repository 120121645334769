import React, { useRef } from "react";
import PropTypes from "prop-types";
import Image from "./Image";
import { useSpring, animated } from "react-spring";

AnimatedProduct.propTypes = {
  productImagePath: PropTypes.string,
  dropFlowers: PropTypes.bool,
};

export default function AnimatedProduct({ productImagePath, dropFlowers }) {
  const n = useRef(0);
  const addStyles = useSpring({
    config: { duration: 1000, friction: 20 },
    loop: () => 0 > n.current++,
    from: { y: dropFlowers === true ? -350 : 0, rotateZ: 15, opacity: 0.6 },
    to: { y: 0, rotateZ: 0, opacity: 1 },
  });

  // const removeStyles = useSpring({
  //   config: { duration: 1000 },
  //   loop: () => 0 > n.current++,
  //   from: { x: 0 },
  //   to: { x: 200 }
  // });

  return (
    productImagePath !== null &&
    productImagePath !== undefined && (
      <animated.div
        style={{
          //    backgroundColor: "#46e891",
          width: 90,
          ...addStyles,
        }}
      >
        <Image
          sx={{
            width: 90,
          }}
          src={`/products/${productImagePath}?w=240&fit=crop&auto=format`}
          srcSet={`/products/${productImagePath}?w=240&fit=crop&auto=format&dpr=2 2x`}
          alt={""}
          loading="lazy"
        />
      </animated.div>
    )
  );
}
