import React from "react";
import FrontFooterGovSign from "./FrontFooterGovSign";
import {
  Container,
  Box,
  Stack,
  Link,
  ButtonGroup,
  Button,
} from "@mui/material";

import { hrefLink } from "../../utils/core";
import { useTranslation } from "react-i18next";

export default function FrontFooter() {
  const { t } = useTranslation();
  return (
    <Box
      component="footer"
      sx={{
        py: 3,
        px: 2,
        mt: "auto",
        background: "linear-gradient(rgba(0, 0, 0, 0.01),rgba(0, 0, 0, 0.9));",
        boxShadow: "none",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <Container maxWidth="lg">
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={0}
        >
          <ButtonGroup
            color="neutral"
            disableRipple={true}
            disableFocusRipple={true}
            disableElevation={true}
            size="small"
            variant="text"
            aria-label="botton menu buttons"
          >
            <Button
              component={Link}
              href={hrefLink("/")}
              color="inherit"
              underline="none"
              sx={{ textTransform: "none", px: 2 }}
            >
              {t("home.menuHome")}
            </Button>
            <Button
              component={Link}
              href={hrefLink("/funerals/new")}
              color="inherit"
              underline="none"
              sx={{ textTransform: "none", px: 2 }}
            >
              {t("home.menuFuneral")}
            </Button>
            <Button
              component={Link}
              href={hrefLink("/funerals/gifts")}
              color="inherit"
              underline="none"
              sx={{ textTransform: "none", px: 2 }}
            >
              {t("home.menuFlowers")}
            </Button>
          </ButtonGroup>
        </Stack>
        <FrontFooterGovSign />
      </Container>
    </Box>
  );
}
