import React from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Grid,
  Typography,
  FormControlLabel,
  TextField,
  Switch
} from '@mui/material';

VictimMemoryForm.propTypes = {
  funeral: PropTypes.object,
  setFieldValue: PropTypes.func,
  t: PropTypes.func
};

export default function VictimMemoryForm({ funeral, setFieldValue, t }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        pt: 2,
        px: 8
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            id="memory"
            label={t('funeral.memory')}
            multiline
            rows={5}
            variant="filled"
            inputProps={{
              name: 'funeral[memory]'
            }}
            value={funeral.memory}
            onChange={(e) =>
              setFieldValue({
                memory: e.target.value
              })
            }
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            labelPlacement="end"
            control={
              <Switch
                size="small"
                checked={funeral.memory_consent}
                onChange={(event) => {
                  setFieldValue({
                    memory_consent: event.target.checked
                  });
                }}
              />
            }
            label={
              <Typography variant="caption">{t('funeral.agree')}</Typography>
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
}
