import ReactOnRails from 'react-on-rails';

import Home from '../bundles/Public/Home/Home';
import Funeral from '../bundles/Public/Home/Funeral';
import PromoFuneral from '../bundles/Public/Home/PromoFuneral';
import SponsoredFuneral from '../bundles/Public/Home/SponsoredFuneral';
import Gifts from '../bundles/Public/Home/Gifts';
import Yards from '../bundles/Public/Home/Yards';
import Cancel from '../bundles/Public/Home/Cancel';
import Confirm from '../bundles/Public/Home/Confirm';
import Failure from '../bundles/Public/Home/Failure';
import Success from '../bundles/Public/Home/Success';
import '../utils/i18n';

// This is how react_on_rails can see the signUp in the browser.
ReactOnRails.register({
  Home,
  Funeral,
  PromoFuneral,
  SponsoredFuneral,
  Gifts,
  Yards,
  Cancel,
  Confirm,
  Failure,
  Success
});
