import React from 'react';
import PropTypes from 'prop-types';
import Image from '../Image';
import AnnotatedGrave from '../AnnotatedGrave';
import {
  Box,
  Grid,
  Typography,
  ImageList,
  ImageListItem,
  FormControlLabel,
  ImageListItemBar,
  Checkbox
} from '@mui/material';

import { useTranslation } from 'react-i18next';

ProductAdd.propTypes = {
  products: PropTypes.array,
  funeral: PropTypes.object,
  locale: PropTypes.string,
  setFieldValue: PropTypes.func,
  currentUser: PropTypes.object
};

export default function ProductAdd({
  products,
  funeral,
  locale,
  setFieldValue,
  currentUser
}) {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        pt: 1
      }}
    >
      <Typography variant="h6" gutterBottom component="div" align="center">
        {t('funeral.floweSelectionTitle')}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={5}>
          <AnnotatedGrave
            grave={products.find(
              (grave) => String(grave.id) === String(funeral.grave_id)
            )}
            products={products.filter(
              (product) => product.kind === 'flower' || product.kind === 'light'
            )}
            decorations={funeral.funeral_decorations_attributes}
            funeral={funeral}
            locale={locale}
            dropFlowers={true}
            showFinal={false}
          />
        </Grid>
        <Grid item xs={12} sm={7}>
          <ImageList cols={3} gap={0} sx={{ height: 500 }}>
            {products !== undefined &&
              products
                .filter((product) => product.kind === 'flower')
                .map((flower) => (
                  <FormControlLabel
                    key={'flower-' + flower.id}
                    labelPlacement="top"
                    control={
                      <Checkbox
                        value={flower.id}
                        checked={
                          funeral.funeral_decorations_attributes.filter(
                            (dec) =>
                              dec.id === undefined &&
                              String(dec.product_id) === String(flower.id)
                          ).length > 0
                        }
                        onChange={(e) => {
                          if (e.target.checked) {
                            setFieldValue({
                              funeral_decorations_attributes: [
                                ...funeral.funeral_decorations_attributes,
                                ...[
                                  {
                                    product_id: e.target.value,
                                    user_id: currentUser.id,
                                    start_date: null,
                                    end_date: null
                                  }
                                ]
                              ]
                            });
                          } else {
                            setFieldValue({
                              funeral_decorations_attributes: [
                                ...funeral.funeral_decorations_attributes.filter(
                                  (dec) =>
                                    dec.id !== undefined ||
                                    String(dec.product_id) !==
                                      String(e.target.value)
                                )
                              ]
                            });
                          }
                        }}
                      />
                    }
                    label={
                      flower.picture !== null &&
                      flower.picture !== undefined && (
                        <ImageListItem key={flower.picture}>
                          <Image
                            src={`/products/${flower.picture}?w=240&fit=crop&auto=format`}
                            srcSet={`/products/${flower.picture}?w=240&fit=crop&auto=format&dpr=2 2x`}
                            alt={flower.name}
                            loading="lazy"
                          />
                          <ImageListItemBar
                            title={
                              <Typography
                                color="secondary" // primary.main
                                variant="h6"
                              >
                                {flower.price !== 0 && flower.price !== null
                                  ? '$ ' + flower.price
                                  : ''}
                              </Typography>
                            }
                            subtitle={flower.name}
                          />
                        </ImageListItem>
                      )
                    }
                  />
                ))}
          </ImageList>
        </Grid>
      </Grid>
    </Box>
  );
}
