import React, { useEffect, useReducer, useCallback } from 'react';
import PropTypes from 'prop-types';
import PublicApp from '../../shared/PublicApp';
import FrontHeader from '../../shared/FrontHeader';
import FrontFooter from '../../shared/FrontFooter';
import StepperButtons from '../../shared/StepperButtons';
import VictimIdentificationForm from '../../shared/funeral/VictimIdentificationForm';
import VictimPhotosForm from '../../shared/funeral/VictimPhotosForm';
import VictimMemoryForm from '../../shared/funeral/VictimMemoryForm';
import Image from '../../shared/Image';
import AnnotatedGrave from '../../shared/AnnotatedGrave';

import ProductAdd from '../../shared/funeral/ProductAdd';
import ShoppingCart from '../../shared/funeral/ShoppingCart';
import {
  Container,
  Box,
  Grid,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Radio,
  RadioGroup,
  ImageList,
  ImageListItem,
  FormControlLabel,
  ImageListItemBar
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import { cloudinaryUploadImage } from '../../../utils/core';

const fielsPerStep = [
  ['grave_id'],
  [
    'first_name',
    'birth_date',
    'death_date',
    'gender',
    'prefecture_id',
    'country_id',
    'country_name'
  ],
  [],
  ['memory']
];

// Thank you for your order.
// Your order number is #2001539. We have emailed your order confirmation, and will send you an update when your order has shipped.

SponsoredFuneral.propTypes = {
  flash: PropTypes.array,
  errors: PropTypes.array,
  prefectures: PropTypes.array,
  products: PropTypes.array,
  countries: PropTypes.array,
  locale: PropTypes.string,
  authenticity_token: PropTypes.string,
  current_user: PropTypes.object,
  signed_in: PropTypes.bool
};

export default function SponsoredFuneral({
  flash,
  errors,
  prefectures,
  products,
  locale,
  authenticity_token,
  countries,
  current_user,
  signed_in
}) {
  const { t, i18n } = useTranslation();

  const steps = [
    t('funeral.step_1'),
    t('funeral.step_2'),
    t('funeral.step_3'),
    t('funeral.step_4'),
    t('funeral.step_5')
  ];

  const handleRemoveAll = () => {
    setFieldValue({
      funeral_pictures_attributes: []
    });
  };

  const handleRemove = (file) => {
    const filteredItems = funeral.funeral_pictures_attributes.filter(
      (_file) => _file.path !== file
    );
    setFieldValue({
      funeral_pictures_attributes: filteredItems
    });
  };

  const handleNext = () => {
    setFieldValue({
      activeStep: funeral.activeStep + 1
    });
  };

  const handleBack = () => {
    setFieldValue({
      activeStep: funeral.activeStep - 1
    });
  };

  const [funeral, dispatch] = useReducer(
    (funeral, action) => {
      switch (action.type) {
        case 'SET_VALUE':
          return { ...funeral, ...action.payload };
        default:
          return funeral;
      }
    },
    // {
    //   grave_id: "11",
    //   first_name: "sdsdfsdf",
    //   last_name: "dsfsdfsdf",
    //   other_names: "dsfsdfsd dsfsdf",
    //   birth_date: "1957-08-23",
    //   death_date: "1994-04-22",
    //   gender: "female",
    //   memory: "asdhkasdjf aksdhfkhsadf",
    //   memory_consent: false,
    //   prefecture_id: "7",
    //   commune: "dsffsd",
    //   secteur: "sdfsd",
    //   cellule: "sdfsd",
    //   country_id: "1",
    //   country_name: "Rwanda",
    //   funeral_pictures_attributes: [],
    //   funeral_decorations_attributes: [],
    //   activeStep: 6,
    //   checkOutTotal: 0,
    //   isUploading: false
    // }
    {
      kind: 'individual',
      grave_id: '',
      first_name: '',
      last_name: '',
      other_names: '',
      birth_date: null,
      death_date: null,
      gender: '',
      memory: '',
      memory_consent: false,
      prefecture_id: '',
      commune: '',
      secteur: '',
      cellule: '',
      country_id: '',
      country_name: '',
      funeral_pictures_attributes: [],
      funeral_decorations_attributes: [],
      activeStep: 0,
      checkOutTotal: 0,
      isUploading: false
    }
  );

  const setFieldValue = (fieldValue) => {
    dispatch({ type: 'SET_VALUE', payload: fieldValue });
  };

  const handleDropMultiFile = useCallback((acceptedFiles, uploadedFiles) => {
    setFieldValue({
      isUploading: true
    });
    const promises = [];

    acceptedFiles.forEach((file) =>
      promises.push(
        cloudinaryUploadImage(
          file,
          'ruhuka_amahoto_test',
          'https://api.cloudinary.com/v1_1/strategic-purchasing/image/upload'
        )
      )
    );

    Promise.all(promises).then((results) => {
      // When all promises are done, this code is executed;
      const newPictures = results.map((result) => ({
        path: result.data.url
      }));
      setFieldValue({
        funeral_pictures_attributes: [...uploadedFiles, ...newPictures],
        isUploading: false
      });
    });
  }, []); // eslint-disable-line

  useEffect(() => {
    i18n.changeLanguage(locale);
  }, [locale]); // eslint-disable-line

  return (
    <PublicApp
      flash={
        flash !== null && flash !== undefined && flash.filter((f) => f !== null)
      }
      errors={errors}
      locale={locale}
      children={
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh'
          }}
        >
          <FrontHeader
            showLogo={true}
            signedIn={signed_in}
            currentUser={current_user}
            authenticityToken={authenticity_token}
            locale={locale}
          />
          <Container
            disableGutters
            maxWidth="lg"
            component="main"
            sx={{ pt: 4, pb: 6 }}
          >
            <Typography
              variant="h4"
              gutterBottom
              component="div"
              align="center"
            >
              {t('funeral.title')}
            </Typography>
            <br />
            <br />

            <Box sx={{ width: '100%' }}>
              <Stepper activeStep={funeral.activeStep}>
                {steps.map((label) => {
                  const stepProps = {};
                  const labelProps = {};
                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
              {funeral.activeStep === steps.length ? (
                <ShoppingCart
                  sponsored={true}
                  newShoppingCart={true}
                  handleBack={handleBack}
                  authenticityToken={authenticity_token}
                  formHiddenFields={['kind', 'memory_consent']}
                  formFields={[
                    'first_name',
                    'last_name',
                    'other_names',
                    'birth_date',
                    'death_date',
                    'gender',
                    'memory',
                    'memory_consent',
                    'prefecture_id',
                    'commune',
                    'secteur',
                    'cellule',
                    'country_id',
                    'country_name',
                    'funeral_pictures_attributes',
                    'funeral_decorations_attributes'
                  ]}
                  displayFields={[
                    {
                      label: t('funeral.firstName'),
                      field: 'first_name'
                    },
                    {
                      label: t('funeral.lastName'),
                      field: 'last_name'
                    },
                    {
                      label: t('funeral.surName'),
                      field: 'other_names'
                    },
                    { label: t('funeral.gender'), field: 'gender' },
                    { label: t('funeral.memory'), field: 'memory' }
                  ]}
                  products={products}
                  funeral={funeral}
                  locale={locale}
                />
              ) : (
                <React.Fragment>
                  <StepperButtons
                    activeStep={funeral.activeStep}
                    handleBack={handleBack}
                    handleNext={handleNext}
                    steps={steps}
                    fields={fielsPerStep[funeral.activeStep]}
                    values={funeral}
                  />
                  {funeral.activeStep === 0 && (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        pt: 2
                      }}
                    >
                      <RadioGroup
                        aria-labelledby="controlled-grave-selector"
                        name="controlled-grave-selector"
                        value={funeral.grave_id}
                        onChange={(e) =>
                          setFieldValue({
                            grave_id: e.target.value,
                            funeral_decorations_attributes: [
                              ...funeral.funeral_decorations_attributes.filter(
                                (dec) => dec.product_id !== funeral.grave_id
                              ),
                              ...[
                                {
                                  product_id: e.target.value,
                                  user_id: current_user.id,
                                  start_date: null,
                                  end_date: null
                                }
                              ]
                            ]
                          })
                        }
                      >
                        <ImageList cols={4} gap={20}>
                          {products !== undefined &&
                            products
                              .filter((product) => product.kind === 'grave')
                              .map((grave) => (
                                <FormControlLabel
                                  key={'grave-' + grave.id}
                                  labelPlacement="top"
                                  value={grave.id}
                                  control={<Radio />}
                                  disabled={!grave.is_active}
                                  label={
                                    <ImageListItem key={grave.picture}>
                                      <Image
                                        src={`/products/${grave.picture}?w=240&fit=crop&auto=format`}
                                        srcSet={`/products/${grave.picture}?w=240&fit=crop&auto=format&dpr=2 2x`}
                                        alt={grave.name}
                                        loading="lazy"
                                      />
                                      <ImageListItemBar
                                        title={
                                          <Typography
                                            color="secondary"
                                            variant="h6"
                                          >
                                            {grave.price !== 0 &&
                                            grave.price !== null
                                              ? '$ ' + grave.price
                                              : ''}
                                          </Typography>
                                        }
                                        subtitle={
                                          grave.is_active === true ? (
                                            grave.name
                                          ) : (
                                            <Typography
                                              color="secondary.light"
                                              variant="h5"
                                            >
                                              {grave.name}
                                            </Typography>
                                          )
                                        }
                                      />
                                    </ImageListItem>
                                  }
                                />
                              ))}
                        </ImageList>
                      </RadioGroup>
                    </Box>
                  )}
                  {funeral.activeStep === 1 && (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        pt: 2
                      }}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={5}>
                          <AnnotatedGrave
                            grave={products.find(
                              (grave) => String(grave.id) === funeral.grave_id
                            )}
                            decorations={funeral.funeral_decorations_attributes}
                            products={products.filter(
                              (product) =>
                                product.kind === 'flower' ||
                                product.kind === 'light'
                            )}
                            funeral={funeral}
                            locale={locale}
                            dropFlowers={false}
                            showFinal={false}
                          />
                        </Grid>
                        <Grid item xs={12} sm={7}>
                          <VictimIdentificationForm
                            funeral={funeral}
                            prefectures={prefectures}
                            countries={countries}
                            setFieldValue={setFieldValue}
                            t={t}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                  {funeral.activeStep === 2 && (
                    <VictimPhotosForm
                      funeral={funeral}
                      handleDropMultiFile={handleDropMultiFile}
                      handleRemoveAll={handleRemoveAll}
                      handleRemove={handleRemove}
                      t={t}
                    />
                  )}
                  {funeral.activeStep === 3 && (
                    <VictimMemoryForm
                      funeral={funeral}
                      setFieldValue={setFieldValue}
                      t={t}
                    />
                  )}
                  {funeral.activeStep === 4 && (
                    <ProductAdd
                      products={products}
                      funeral={funeral}
                      locale={locale}
                      setFieldValue={setFieldValue}
                      currentUser={current_user}
                    />
                  )}
                  <StepperButtons
                    activeStep={funeral.activeStep}
                    handleBack={handleBack}
                    handleNext={handleNext}
                    steps={steps}
                    fields={fielsPerStep[funeral.activeStep]}
                    values={funeral}
                  />
                </React.Fragment>
              )}
            </Box>
          </Container>
          <FrontFooter
            currentUser={current_user}
            authenticityToken={authenticity_token}
            locale={locale}
          />
        </Box>
      }
    />
  );
}
