import React from 'react';
import { isObject, isArray } from 'lodash';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import frLocale from 'date-fns/locale/fr';
import enLocale from 'date-fns/locale/en-US';
import AnnotatedGrave from '../AnnotatedGrave';
import FrontButton from '../FrontButton';
import {
  Box,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar
} from '@mui/material';

import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';
import NavigateBeforeOutlinedIcon from '@mui/icons-material/NavigateBeforeOutlined';

import { hrefLink } from '../../../utils/core';
import { useTranslation } from 'react-i18next';

ShoppingCart.propTypes = {
  sponsored: PropTypes.bool,
  products: PropTypes.array,
  funeral: PropTypes.object,
  locale: PropTypes.string,
  newShoppingCart: PropTypes.bool,
  formHiddenFields: PropTypes.array,
  formFields: PropTypes.array,
  displayFields: PropTypes.array,
  authenticityToken: PropTypes.string,
  handleBack: PropTypes.func
};

const localeMap = {
  en: enLocale,
  fr: frLocale,
  rw: frLocale
};

export default function ShoppingCart({
  sponsored,
  products,
  funeral,
  locale,
  newShoppingCart,
  formHiddenFields,
  authenticityToken,
  formFields,
  displayFields,
  handleBack
}) {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          pt: 2
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={5}>
            <AnnotatedGrave
              grave={products.find(
                (grave) => String(grave.id) === String(funeral.grave_id)
              )}
              decorations={funeral.funeral_decorations_attributes}
              products={products.filter(
                (product) =>
                  product.kind === 'flower' || product.kind === 'light'
              )}
              funeral={funeral}
              locale={locale}
              dropFlowers={true}
              showFinal={true}
            />
          </Grid>
          <Grid item xs={12} sm={7}>
            <Typography variant="h5" gutterBottom sx={{ py: 1.3 }}>
              {t('funeral.identification')}
            </Typography>
            <List disablePadding>
              {displayFields !== null &&
                displayFields.map(
                  (info) =>
                    funeral[info.field] !== null &&
                    funeral[info.field] !== undefined &&
                    funeral[info.field] !== '' && (
                      <ListItem
                        key={'info-' + info.field}
                        sx={{ py: 0.3, px: 0 }}
                      >
                        <ListItemText
                          primary={info.label}
                          secondary={funeral[info.field]}
                        />
                      </ListItem>
                    )
                )}
              <ListItem sx={{ py: 0.3, px: 0 }}>
                <ListItemText
                  primary={t('funeral.dates')}
                  secondary={
                    format(new Date(funeral.birth_date), 'yyyy MMMM dd', {
                      locale: localeMap[locale]
                    }) +
                    ' -- ✝ ' +
                    format(new Date(funeral.death_date), 'yyyy MMMM dd', {
                      locale: localeMap[locale]
                    })
                  }
                />
              </ListItem>
              {funeral.funeral_messages_attributes !== undefined &&
                funeral.funeral_messages_attributes[0] !== undefined &&
                funeral.funeral_messages_attributes[0].post !== null &&
                funeral.funeral_messages_attributes[0].post !== undefined &&
                funeral.funeral_messages_attributes[0].post !== '' && (
                  <ListItem sx={{ py: 0.3, px: 0 }}>
                    <ListItemText
                      primary={t('funeral.message')}
                      secondary={
                        <Typography
                          color="textSecondary"
                          component="div"
                          variant="caption"
                        >
                          {funeral.funeral_messages_attributes[0].post}
                        </Typography>
                      }
                    />
                  </ListItem>
                )}
              <Typography variant="h5" gutterBottom sx={{ py: 1 }}>
                {t('funeral.billable')}
              </Typography>
              {funeral.funeral_decorations_attributes
                .filter((dec) => dec.id === undefined)
                .map((deco) => {
                  const prod = products.find(
                    (prd) => String(prd.id) === String(deco.product_id)
                  );
                  return (
                    <ListItem
                      key={'products-checkout-' + prod.id}
                      sx={{ py: 0.3, px: 0 }}
                    >
                      <ListItemAvatar>
                        <Avatar
                          alt={prod.name}
                          src={'/products/' + prod.picture}
                        />
                      </ListItemAvatar>
                      <ListItemText primary={prod.name} />
                      <Typography variant="body2">
                        {'$ ' + prod.price}
                      </Typography>
                    </ListItem>
                  );
                })}

              <ListItem sx={{ py: 1, px: 0 }}>
                <ListItemText primary={t('funeral.total')} />
                <Typography variant="h5" sx={{ fontWeight: 700 }}>
                  {'$ ' +
                    products
                      .filter((product) =>
                        funeral.funeral_decorations_attributes
                          .filter((dec) => dec.id === undefined)
                          .map((dec) => String(dec.product_id))
                          .includes(String(product.id))
                      )
                      .reduce((sum, product) => sum + product.price, 0)}
                </Typography>
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
        <Box sx={{ flex: '1 1 auto' }} />
        <form
          role="form"
          acceptCharset="UTF-8"
          action={
            sponsored === true
              ? funeral.id !== '' &&
                funeral.id !== null &&
                funeral.id !== undefined
                ? hrefLink('/funerals/' + funeral.id)
                : hrefLink('/funerals')
              : hrefLink('/funerals/redeem')
          }
          method="post"
        >
          <input
            type="hidden"
            name="authenticity_token"
            value={authenticityToken}
          />
          {formHiddenFields !== undefined &&
            formHiddenFields.map((formHiddenField) => (
              <input
                key={'hidden-field-' + formHiddenField}
                type="hidden"
                name={'funeral[' + formHiddenField + ']'}
                value={funeral[formHiddenField]}
              />
            ))}
          {newShoppingCart === true ? (
            <input type="hidden" name="_method" value="POST" />
          ) : (
            <input type="hidden" name="_method" value="PATCH" />
          )}
          {Object.keys(funeral)
            .filter((formKey) => formFields.includes(formKey))
            .map((formKey) => {
              //  console.log(funeral[formKey]);
              if (isArray(funeral[formKey])) {
                //  console.log(funeral[formKey] + " is array");
                return funeral[formKey].map((formAttrib, formAttribIndex) => {
                  if (isObject(formAttrib)) {
                    return Object.keys(formAttrib).map(
                      (formAttribKey, formAttribKeyIndex) => {
                        return (
                          <input
                            key={
                              'funeral-' +
                              formKey +
                              '-' +
                              formAttribKeyIndex +
                              '-' +
                              formAttribKey
                            }
                            type="hidden"
                            name={
                              'funeral[' +
                              formKey +
                              '][][' +
                              formAttribKey +
                              ']'
                            }
                            value={formAttrib[formAttribKey]}
                          />
                        );
                      }
                    );
                  } else {
                    return (
                      <input
                        key={'funeral-' + formKey + '-' + formAttribIndex}
                        type="hidden"
                        name={'funeral[' + formKey + '][]'}
                        value={formAttrib}
                      />
                    );
                  }
                });
              } else {
                return (
                  <input
                    key={'funeral-' + formKey}
                    type="hidden"
                    name={'funeral[' + formKey + ']'}
                    value={funeral[formKey]}
                  />
                );
              }
            })}

          <FrontButton
            children={t('funeral.backButton')}
            onClick={handleBack}
            startIcon={<NavigateBeforeOutlinedIcon />}
          />

          <FrontButton
            disabled={
              funeral.funeral_decorations_attributes.filter(
                (dec) => dec.id === undefined
              ).length === 0 ||
              products
                .filter((product) =>
                  funeral.funeral_decorations_attributes
                    .filter((dec) => dec.id === undefined)
                    .map((dec) => String(dec.product_id))
                    .includes(String(product.id))
                )
                .reduce((sum, product) => sum + product.price, 0) <= 0
            }
            children={t('funeral.finishButton')}
            type="submit"
            endIcon={<NavigateNextOutlinedIcon />}
          />
        </form>
      </Box>
    </React.Fragment>
  );
}
