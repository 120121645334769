import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import PublicApp from '../../shared/PublicApp';
import FrontHeader from '../../shared/FrontHeader';
import FrontFooter from '../../shared/FrontFooter';
import CarouselArrows from '../../shared/CarouselArrows';
import AnnotatedGrave from '../../shared/AnnotatedGrave';
import FrontButton from '../../shared/FrontButton';
import EditMemoryForm from '../../shared/funeral/EditMemoryForm';

import Slider from 'react-slick';
import SimpleImageSlider from 'react-simple-image-slider';

import {
  Container,
  Box,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  IconButton,
  // useMediaQuery,
  // useTheme,
  Stack,
  Divider
} from '@mui/material';

import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import { useTranslation } from 'react-i18next';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

Yards.propTypes = {
  funerals: PropTypes.array,
  flash: PropTypes.array,
  errors: PropTypes.array,
  locale: PropTypes.string,
  title: PropTypes.string,
  products: PropTypes.array,
  current_user: PropTypes.object,
  authenticity_token: PropTypes.string,
  signed_in: PropTypes.bool
};

export default function Yards({
  flash,
  errors,
  locale,
  current_user,
  signed_in,
  title,
  products,
  funerals,
  authenticity_token
}) {
  // const theme = useTheme();
  const { t, i18n } = useTranslation();
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [selected, setSelected] = useState(null);
  const [editMemory, setEditMemory] = useState(false);
  const carouselRef = useRef(null);
  // const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleDetailsOpen = () => {
    setDetailsOpen(true);
  };

  const handleDetailsClose = () => {
    setDetailsOpen(false);
  };
  const settings = {
    //speed: 200,
    swipe: false,
    dots: false,
    arrows: false,
    infinite: true,
    rows: 1,
    slidesToScroll: 1,
    variableWidth: false,
    slidesToShow: funerals.length >= 3 ? 3 : funerals.length,
    centerMode: true,
    centerPadding: '0',
    rtl: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: { slidesToShow: 1 }
      },
      {
        breakpoint: 600,
        settings: { slidesToShow: 1 }
      },
      {
        breakpoint: 480,
        settings: { slidesToShow: 1, centerPadding: '0' }
      }
    ]
  };

  const handlePrevious = () => {
    carouselRef.current?.slickPrev();
  };

  const handleNext = () => {
    carouselRef.current?.slickNext();
  };

  const handleMemoryEditOpen = () => {
    setEditMemory(!editMemory);
  };

  useEffect(() => {
    i18n.changeLanguage(locale);
  }, [locale]); // eslint-disable-line

  return (
    <PublicApp
      sx={{
        backgroundImage: `url(${'/inside-min.jpg'})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      }}
      flash={
        flash !== null && flash !== undefined && flash.filter((f) => f !== null)
      }
      errors={errors}
      locale={locale}
      children={
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            background:
              'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));',
            boxShadow: 'none'
          }}
        >
          <FrontHeader
            showLogo={true}
            signedIn={signed_in}
            currentUser={current_user}
            authenticityToken={authenticity_token}
            locale={locale}
          />

          <Container
            disableGutters
            maxWidth="lg"
            component="main"
            sx={{ pt: 8, pb: 6 }}
          >
            <Typography
              sx={{
                textAlign: 'center',
                textShadow: '#FFF 2px 0 15px;'
              }}
              variant="h4"
              gutterBottom
              component="div"
            >
              {title}
            </Typography>
            <CarouselArrows
              filled
              onNext={handleNext}
              onPrevious={handlePrevious}
              sx={{
                '& .arrow': {
                  '&.left': { left: 8 },
                  '&.right': { right: 8 }
                }
              }}
            >
              <Slider ref={carouselRef} {...settings}>
                {funerals.map((funeral) => {
                  const grave = funeral.decorations.find(
                    (prod) => prod.kind === 'grave'
                  );
                  return (
                    grave !== undefined && (
                      <Box
                        key={'funeral-slide-' + funeral.id}
                        sx={{ cursor: 'pointer' }}
                        onClick={() => {
                          setSelected(funeral);
                          handleDetailsOpen();
                        }}
                      >
                        <AnnotatedGrave
                          dropFlowers={false}
                          showFinal={true}
                          grave={grave}
                          products={products}
                          decorations={funeral.decorations.filter(
                            (product) =>
                              product.kind === 'flower' ||
                              product.kind === 'light'
                          )}
                          funeral={funeral}
                        />
                      </Box>
                    )
                  );
                })}
              </Slider>
            </CarouselArrows>
            {selected !== null && (
              <Dialog
                fullWidth={true}
                maxWidth="md"
                open={detailsOpen}
                onClose={() => {
                  setSelected(null);
                  handleDetailsClose();
                }}
                scroll={'paper'}
                aria-labelledby="responsive-funeral-slide-title"
              >
                <DialogTitle id="responsive-funeral-slide-title">
                  <List>
                    <ListItem>
                      <ListItemText
                        primary={
                          <Typography variant="h5" component="div">
                            {selected.first_name +
                              ' ' +
                              selected.last_name +
                              ' ' +
                              selected.other_names}
                          </Typography>
                        }
                        secondary={
                          <div>
                            <Typography variant="caption" component="div">
                              {t('home.birth') + ': ' + selected.birth_date}
                            </Typography>
                            <Typography variant="caption" component="div">
                              {t('home.assassinated') +
                                ': ' +
                                selected.death_date}
                            </Typography>
                            {selected.memory_consent === true && (
                              <React.Fragment>
                                {editMemory === false && (
                                  <Stack
                                    mt={1}
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="flex-end"
                                    spacing={1}
                                  >
                                    {selected.author !== null &&
                                      current_user !== null &&
                                      String(selected.author.id) ===
                                        String(current_user.id) && (
                                        <IconButton
                                          aria-label="edit-memory"
                                          size="small"
                                          onClick={() => handleMemoryEditOpen()}
                                        >
                                          <EditOutlinedIcon fontSize="small" />
                                        </IconButton>
                                      )}
                                    <DialogContentText>
                                      {selected.memory}
                                    </DialogContentText>
                                  </Stack>
                                )}
                                {editMemory === true && (
                                  <EditMemoryForm
                                    handleMemoryEditOpen={handleMemoryEditOpen}
                                    funeral={selected}
                                    authenticityToken={authenticity_token}
                                  />
                                )}
                              </React.Fragment>
                            )}
                          </div>
                        }
                      />
                    </ListItem>
                  </List>
                </DialogTitle>
                <DialogContent>
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={1}
                  >
                    <Box>
                      {selected.pictures !== undefined &&
                        selected.pictures.length > 0 && (
                          <SimpleImageSlider
                            style={{ margin: 'auto', textAlign: 'center' }}
                            width={400}
                            height={280}
                            images={selected.pictures}
                            showBullets={true}
                            showNavs={true}
                          />
                        )}
                      <br />
                      <Typography
                        variant="caption"
                        gutterBottom
                        component="div"
                      >
                        {t('home.gender') +
                          ': ' +
                          t('funeral.' + selected.gender)}
                      </Typography>
                      <Typography
                        variant="caption"
                        gutterBottom
                        component="div"
                      >
                        {t('funeral.prefecture') +
                          ': ' +
                          selected.prefecture.name}
                      </Typography>
                      {selected.commune !== '' &&
                        selected.commune !== null &&
                        selected.commune !== undefined && (
                          <Typography
                            variant="caption"
                            gutterBottom
                            component="div"
                          >
                            {t('funeral.commune') + ': ' + selected.commune}
                          </Typography>
                        )}
                      {selected.secteur !== '' &&
                        selected.secteur !== null &&
                        selected.secteur !== undefined && (
                          <Typography
                            variant="caption"
                            gutterBottom
                            component="div"
                          >
                            {t('funeral.sector') + ': ' + selected.secteur}
                          </Typography>
                        )}
                      {selected.secteur !== '' &&
                        selected.cellule !== null &&
                        selected.cellule !== undefined && (
                          <Typography
                            variant="caption"
                            gutterBottom
                            component="div"
                          >
                            {t('funeral.cell') + ': ' + selected.cellule}
                          </Typography>
                        )}
                    </Box>
                    <Box>
                      <List
                        sx={{
                          width: '100%'
                        }}
                      >
                        {selected.messages.length > 0 &&
                          selected.messages !== null &&
                          selected.messages
                            .filter((m) => m.consent === true)
                            .map((message) => (
                              <>
                                <ListItem>
                                  <ListItemText
                                    primary={message.author}
                                    secondary={
                                      <Typography
                                        color="textSecondary"
                                        component="i"
                                        variant="caption"
                                      >
                                        {message.post}
                                      </Typography>
                                    }
                                  />
                                </ListItem>
                                <Divider component="li" />
                              </>
                            ))}
                      </List>
                    </Box>
                  </Stack>
                </DialogContent>
                <DialogActions>
                  <FrontButton
                    children={t('home.continue')}
                    onClick={() => {
                      setSelected(null);
                      handleDetailsClose();
                    }}
                  />
                </DialogActions>
              </Dialog>
            )}
          </Container>
          <FrontFooter
            currentUser={current_user}
            authenticityToken={authenticity_token}
            locale={locale}
          />
        </Box>
      }
    />
  );
}
