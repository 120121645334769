import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import PublicApp from '../../shared/PublicApp';
import FrontHeader from '../../shared/FrontHeader';
import FrontFooter from '../../shared/FrontFooter';
import FrontButton from '../../shared/FrontButton';
import Image from '../../shared/Image';
import { Container, Box, Grid, Typography } from '@mui/material';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';

import { Spring, animated } from 'react-spring';

import { useTranslation } from 'react-i18next';
import { hrefLink } from '../../../utils/core';

Home.propTypes = {
  prefectures: PropTypes.array,
  flash: PropTypes.array,
  errors: PropTypes.array,
  locale: PropTypes.string,
  current_user: PropTypes.object,
  authenticity_token: PropTypes.string,
  signed_in: PropTypes.bool
};

export default function Home({
  flash,
  errors,
  locale,
  current_user,
  signed_in,
  prefectures,
  authenticity_token
}) {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(locale);
  }, [locale]); // eslint-disable-line
  return (
    <PublicApp
      sx={{
        backgroundImage: `url(${'background_img_entry.jpg'})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      }}
      flash={
        flash !== null && flash !== undefined && flash.filter((f) => f !== null)
      }
      errors={errors}
      locale={locale}
      children={
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            background:
              'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));',
            boxShadow: 'none'
          }}
        >
          <FrontHeader
            showLogo={false}
            signedIn={signed_in}
            currentUser={current_user}
            authenticityToken={authenticity_token}
            locale={locale}
          />
          <Container
            disableGutters
            maxWidth="lg"
            component="main"
            sx={{ pt: 8, pb: 6 }}
          >
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={12} sm={5}>
                <Image
                  src={`/gouv_logo_trans.png?w=240&fit=crop&auto=format`}
                  srcSet={`/gouv_logo_trans.png?w=240&fit=crop&auto=format&dpr=2 2x`}
                  alt={t('home.title')}
                  loading="lazy"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Spring
                  loop
                  from={{ opacity: 0.4 }}
                  to={[{ opacity: 1 }, { opacity: 0.4 }]}
                >
                  {(styles) => (
                    <animated.div style={styles}>
                      <FrontButton
                        href={hrefLink('/home/yards')}
                        children={
                          <Typography variant="h5" component="div">
                            {t('home.entryButton')}
                          </Typography>
                        }
                        size="large"
                        endIcon={<ArrowForwardOutlinedIcon />}
                      />
                    </animated.div>
                  )}
                </Spring>
              </Grid>

              <Grid item xs={12} sm={3}>
                <Typography
                  sx={{
                    textShadow: '#FFF 2px 0 20px;'
                  }}
                  variant="body1"
                  gutterBottom
                  component="div"
                >
                  {t('home.prefectureListTitle')}
                </Typography>
                {prefectures.map((prefecture) => (
                  <FrontButton
                    key={'button-prefecture-' + prefecture.id}
                    disabled={prefecture.funeral_count === 0}
                    href={hrefLink('/home/yards/' + prefecture.id)}
                    fullWidth
                    children={
                      <Typography variant="subtitle2" component="div">
                        {prefecture.name}
                      </Typography>
                    }
                  />
                ))}
              </Grid>
            </Grid>
          </Container>
          <FrontFooter
            signedIn={signed_in}
            currentUser={current_user}
            authenticityToken={authenticity_token}
            locale={locale}
          />
        </Box>
      }
    />
  );
}
