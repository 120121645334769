import React from "react";
import PropTypes from "prop-types";
import { Paper, Typography } from "@mui/material";
import MUIDataTable from "mui-datatables";

PublicDataTable.propTypes = {
  title: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  data: PropTypes.array,
  columns: PropTypes.array,
  options: PropTypes.object,
  setSelectedFuneral: PropTypes.func,
};

export default function PublicDataTable({
  title,
  searchPlaceholder,
  data,
  columns,
  options,
  setSelectedFuneral,
}) {
  return (
    <Paper
      variant="outlined"
      sx={{
        p: 2,
        borderRadius: 2,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <MUIDataTable
        title={
          <Typography component="h4" variant="h6" color="primary">
            {title}
          </Typography>
        }
        data={data}
        columns={columns}
        options={{
          elevation: 0,
          selectableRows: "none",
          selectableRowsHeader: false,
          rowsPerPageOptions: [10, 15, 50, 100],
          responsive: "standard",
          serverSide: false,
          rowsPerPage: 8,
          download: false,
          searchOpen: true,
          searchAlwaysOpen: true,
          searchPlaceholder: searchPlaceholder,
          filter: false,
          print: false,
          viewColumns: false,
          pagination: false,
          onRowClick: (rowData) => {
            setSelectedFuneral(rowData[0]);
          },
          setTableProps: () => ({
            densetable: "true",
            padding: "none",
            size: "small",
          }),
          ...options,
        }}
      />
    </Paper>
  );
}
