import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { CssBaseline, Box } from "@mui/material";
import frLocale from "date-fns/locale/fr";
import enLocale from "date-fns/locale/en-US";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Flash from "./Flash";

import { useTranslation } from "react-i18next";

const localeMap = {
  en: enLocale,
  fr: frLocale,
  rw: frLocale,
};

PublicApp.propTypes = {
  locale: PropTypes.string,
  flash: PropTypes.array,
  errors: PropTypes.array,
};

export default function PublicApp({ locale, errors, flash, ...other }) {
  const { i18n } = useTranslation();

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
      secondary: {
        light: "#ffe0b2",
        main: "#ffc107",
        contrastText: "#ffc107",
      },
      neutral: {
        main: "#fff",
        contrastText: "#fff",
      },
    },
  });

  useEffect(() => {
    i18n.changeLanguage(locale);
  }, [locale]); // eslint-disable-line

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        locale={localeMap[locale]}
      >
        <Flash flash={flash} errors={errors} />
        <Box {...other} />
      </LocalizationProvider>
    </ThemeProvider>
  );
}
