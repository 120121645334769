import React from "react";
import PropTypes from "prop-types";
import { ToggleButtonGroup, ToggleButton } from "@mui/material";

import { langHrefLink } from "../../utils/core";

LanguageSelector.propTypes = {
  locale: PropTypes.string
};

export default function LanguageSelector({ locale }) {
  return (
    <ToggleButtonGroup
      exclusive={true}
      size="small"
      value={locale}
      onChange={() => {}}
    >
      <ToggleButton
        sx={{
          fontWeight: locale === "fr" ? 900 : undefined,
          fontSize: 10,
          my: 0.1,
          background: "linear-gradient(rgba(0, 0, 0, 0.1),rgba(0, 0, 0, 0.9));",
          boxShadow: "none"
        }}
        size="small"
        value="fr"
        key="fr"
        onClick={() => langHrefLink("fr")}
      >
        fr
      </ToggleButton>
      <ToggleButton
        sx={{
          fontWeight: locale === "en" ? 900 : undefined,
          fontSize: 10,
          my: 0.1,
          background: "linear-gradient(rgba(0, 0, 0, 0.1),rgba(0, 0, 0, 0.9));",
          boxShadow: "none"
        }}
        size="small"
        value="en"
        key="en"
        onClick={() => langHrefLink("en")}
      >
        en
      </ToggleButton>
      <ToggleButton
        sx={{
          fontWeight: locale === "rw" ? 900 : undefined,
          fontSize: 10,
          my: 0.1,
          background: "linear-gradient(rgba(0, 0, 0, 0.1),rgba(0, 0, 0, 0.9));",
          boxShadow: "none"
        }}
        size="small"
        value="rw"
        key="rw"
        onClick={() => langHrefLink("rw")}
      >
        rw
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
