import React, { useEffect, useReducer, useCallback } from 'react';
import PropTypes from 'prop-types';
import PublicApp from '../../shared/PublicApp';
import FrontHeader from '../../shared/FrontHeader';
import FrontFooter from '../../shared/FrontFooter';
import StepperButtons from '../../shared/StepperButtons';
import VictimIdentificationForm from '../../shared/funeral/VictimIdentificationForm';
import VictimPhotosForm from '../../shared/funeral/VictimPhotosForm';
import VictimMemoryForm from '../../shared/funeral/VictimMemoryForm';
import AnnotatedGrave from '../../shared/AnnotatedGrave';
import ShoppingCart from '../../shared/funeral/ShoppingCart';
import {
  Container,
  Box,
  Grid,
  Typography,
  Stepper,
  Step,
  StepLabel
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import { cloudinaryUploadImage } from '../../../utils/core';

const fielsPerStep = [
  [
    'first_name',
    'birth_date',
    'death_date',
    'gender',
    'prefecture_id',
    'country_id',
    'country_name'
  ],
  [],
  ['memory']
];

// Thank you for your order.
// Your order number is #2001539. We have emailed your order confirmation, and will send you an update when your order has shipped.

PromoFuneral.propTypes = {
  flash: PropTypes.array,
  errors: PropTypes.array,
  prefectures: PropTypes.array,
  products: PropTypes.array,
  countries: PropTypes.array,
  grave_id: PropTypes.number,
  funeral_decorations: PropTypes.array,
  promo_code: PropTypes.string,
  locale: PropTypes.string,
  authenticity_token: PropTypes.string,
  current_user: PropTypes.object,
  signed_in: PropTypes.bool
};

export default function PromoFuneral({
  flash,
  errors,
  prefectures,
  products,
  promo_code,
  locale,
  authenticity_token,
  countries,
  grave_id,
  funeral_decorations,
  current_user,
  signed_in
}) {
  const { t, i18n } = useTranslation();

  const steps = [t('funeral.step_2'), t('funeral.step_3'), t('funeral.step_4')];

  const handleRemoveAll = () => {
    setFieldValue({
      funeral_pictures_attributes: []
    });
  };

  const handleRemove = (file) => {
    const filteredItems = funeral.funeral_pictures_attributes.filter(
      (_file) => _file.path !== file
    );
    setFieldValue({
      funeral_pictures_attributes: filteredItems
    });
  };

  const handleNext = () => {
    setFieldValue({
      activeStep: funeral.activeStep + 1
    });
  };

  const handleBack = () => {
    setFieldValue({
      activeStep: funeral.activeStep - 1
    });
  };

  const [funeral, dispatch] = useReducer(
    (funeral, action) => {
      switch (action.type) {
        case 'SET_VALUE':
          return { ...funeral, ...action.payload };
        default:
          return funeral;
      }
    },
    // {
    //   kind: "individual",
    //   grave_id: grave_id,
    //   first_name: "sdsdfsdf",
    //   last_name: "dsfsdfsdf",
    //   other_names: "dsfsdfsd dsfsdf",
    //   birth_date: "1957-08-23",
    //   death_date: "1994-04-22",
    //   gender: "female",
    //   memory: "asdhkasdjf aksdhfkhsadf",
    //   memory_consent: false,
    //   prefecture_id: "7",
    //   commune: "dsffsd",
    //   secteur: "sdfsd",
    //   cellule: "sdfsd",
    //   country_id: "1",
    //   country_name: "Rwanda",
    //   funeral_pictures_attributes: [],
    //   funeral_decorations_attributes: [],
    //   activeStep: 6,
    //   checkOutTotal: 0,
    //   isUploading: false
    // }
    {
      kind: 'individual',
      grave_id: grave_id,
      promo_code: promo_code,
      first_name: '',
      last_name: '',
      other_names: '',
      birth_date: null,
      death_date: null,
      gender: '',
      memory: '',
      memory_consent: false,
      prefecture_id: '',
      commune: '',
      secteur: '',
      cellule: '',
      country_id: '',
      country_name: '',
      funeral_pictures_attributes: [],
      funeral_decorations_attributes: funeral_decorations,
      activeStep: 0,
      checkOutTotal: 0,
      isUploading: false
    }
  );

  const setFieldValue = (fieldValue) => {
    dispatch({ type: 'SET_VALUE', payload: fieldValue });
  };

  const handleDropMultiFile = useCallback((acceptedFiles, uploadedFiles) => {
    setFieldValue({
      isUploading: true
    });
    const promises = [];

    acceptedFiles.forEach((file) =>
      promises.push(
        cloudinaryUploadImage(
          file,
          'ruhuka_amahoto_test',
          'https://api.cloudinary.com/v1_1/strategic-purchasing/image/upload'
        )
      )
    );

    Promise.all(promises).then((results) => {
      // When all promises are done, this code is executed;
      const newPictures = results.map((result) => ({
        path: result.data.url
      }));
      setFieldValue({
        funeral_pictures_attributes: [...uploadedFiles, ...newPictures],
        isUploading: false
      });
    });
  }, []); // eslint-disable-line

  useEffect(() => {
    i18n.changeLanguage(locale);
  }, [locale]); // eslint-disable-line

  return (
    <PublicApp
      flash={
        flash !== null && flash !== undefined && flash.filter((f) => f !== null)
      }
      errors={errors}
      locale={locale}
      children={
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh'
          }}
        >
          <FrontHeader
            showLogo={true}
            signedIn={signed_in}
            currentUser={current_user}
            authenticityToken={authenticity_token}
            locale={locale}
          />
          <Container
            disableGutters
            maxWidth="lg"
            component="main"
            sx={{ pt: 4, pb: 6 }}
          >
            <Typography
              variant="h4"
              gutterBottom
              component="div"
              align="center"
            >
              {t('funeral.title')}
            </Typography>
            <br />
            <br />

            <Box sx={{ width: '100%' }}>
              <Stepper activeStep={funeral.activeStep}>
                {steps.map((label) => {
                  const stepProps = {};
                  const labelProps = {};
                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
              {funeral.activeStep === steps.length ? (
                <ShoppingCart
                  sponsored={false}
                  newShoppingCart={true}
                  handleBack={handleBack}
                  authenticityToken={authenticity_token}
                  formHiddenFields={['kind', 'memory_consent', 'promo_code']}
                  formFields={[
                    'first_name',
                    'last_name',
                    'other_names',
                    'birth_date',
                    'death_date',
                    'gender',
                    'memory',
                    'promo_code',
                    'memory_consent',
                    'prefecture_id',
                    'commune',
                    'secteur',
                    'cellule',
                    'country_id',
                    'country_name',
                    'funeral_pictures_attributes',
                    'funeral_decorations_attributes'
                  ]}
                  displayFields={[
                    {
                      label: t('funeral.firstName'),
                      field: 'first_name'
                    },
                    {
                      label: t('funeral.lastName'),
                      field: 'last_name'
                    },
                    {
                      label: t('funeral.surName'),
                      field: 'other_names'
                    },
                    { label: t('funeral.gender'), field: 'gender' },
                    { label: t('funeral.memory'), field: 'memory' }
                  ]}
                  products={products}
                  funeral={funeral}
                  locale={locale}
                />
              ) : (
                <React.Fragment>
                  <StepperButtons
                    activeStep={funeral.activeStep}
                    handleBack={handleBack}
                    handleNext={handleNext}
                    steps={steps}
                    fields={fielsPerStep[funeral.activeStep]}
                    values={funeral}
                  />
                  {funeral.activeStep === 0 && (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        pt: 2
                      }}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={5}>
                          <AnnotatedGrave
                            grave={products.find(
                              (grave) =>
                                String(grave.id) === String(funeral.grave_id)
                            )}
                            decorations={funeral_decorations}
                            products={products.filter(
                              (product) =>
                                product.kind === 'flower' ||
                                product.kind === 'light'
                            )}
                            funeral={funeral}
                            locale={locale}
                            dropFlowers={true}
                            showFinal={true}
                          />
                        </Grid>
                        <Grid item xs={12} sm={7}>
                          <VictimIdentificationForm
                            funeral={funeral}
                            prefectures={prefectures}
                            countries={countries}
                            setFieldValue={setFieldValue}
                            t={t}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                  {funeral.activeStep === 1 && (
                    <VictimPhotosForm
                      funeral={funeral}
                      handleDropMultiFile={handleDropMultiFile}
                      handleRemoveAll={handleRemoveAll}
                      handleRemove={handleRemove}
                      t={t}
                    />
                  )}
                  {funeral.activeStep === 2 && (
                    <VictimMemoryForm
                      funeral={funeral}
                      setFieldValue={setFieldValue}
                      t={t}
                    />
                  )}

                  <StepperButtons
                    activeStep={funeral.activeStep}
                    handleBack={handleBack}
                    handleNext={handleNext}
                    steps={steps}
                    fields={fielsPerStep[funeral.activeStep]}
                    values={funeral}
                  />
                </React.Fragment>
              )}
            </Box>
          </Container>
          <FrontFooter
            currentUser={current_user}
            authenticityToken={authenticity_token}
            locale={locale}
          />
        </Box>
      }
    />
  );
}
