import React, { useEffect } from "react";
import PropTypes from "prop-types";
import PublicApp from "../../shared/PublicApp";
import FrontHeader from "../../shared/FrontHeader";
import FrontFooter from "../../shared/FrontFooter";
import { Container, Box, Grid, Typography } from "@mui/material";

import { useTranslation } from "react-i18next";
import { hrefLink } from "../../../utils/core";

Failure.propTypes = {
  flash: PropTypes.array,
  errors: PropTypes.array,
  locale: PropTypes.string,
  authenticity_token: PropTypes.string,
  current_user: PropTypes.string,
  signed_in: PropTypes.bool,
};

export default function Failure({
  flash,
  errors,
  locale,
  authenticity_token,
  current_user,
  signed_in,
}) {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(locale);
  }, [locale]); // eslint-disable-line
  return (
    <PublicApp
      sx={{
        backgroundImage: `url(${"/inside-min.jpg"})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
      flash={
        flash !== null && flash !== undefined && flash.filter((f) => f !== null)
      }
      errors={errors}
      locale={locale}
      children={
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh",
            background:
              "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));",
            boxShadow: "none",
          }}
        >
          <FrontHeader
            showLogo={true}
            signedIn={signed_in}
            currentUser={current_user}
            authenticityToken={authenticity_token}
            locale={locale}
          />
          <Container
            disableGutters
            maxWidth="lg"
            component="main"
            sx={{ pt: 15, pb: 6 }}
          >
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-end"
            >
              <Box
                sx={{
                  textAlign: "center",
                  color: "#000000",
                  padding: 10,
                  width: 600,
                  height: 500,
                  borderRadius: 5,
                  backgroundColor: "#FFFFFF",
                  opacity: [0.9, 0.8, 0.6],
                }}
              >
                <Typography variant="h4" gutterBottom component="div">
                  {t("shop.paymentCancelTitle")}
                </Typography>

                <Typography
                  variant="subtitle1"
                  gutterBottom
                  component="div"
                  sx={{
                    color: "#000000",
                  }}
                >
                  {t("shop.paymentCancelMessage")}
                </Typography>

                <Typography
                  variant="subtitle1"
                  gutterBottom
                  component="a"
                  href={hrefLink("/funerals/gifts")}
                  sx={{
                    color: "#000000",
                  }}
                >
                  {t("shop.tryAgain")}
                </Typography>
              </Box>
            </Grid>
          </Container>
          <FrontFooter
            currentUser={current_user}
            authenticityToken={authenticity_token}
            locale={locale}
          />
        </Box>
      }
    />
  );
}
