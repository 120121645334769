import React, { useState } from "react";
import PropTypes from "prop-types";
import FrontButton from "./FrontButton";
import LanguageSelector from "./LanguageSelector";
import {
  AppBar,
  Toolbar,
  Box,
  CardHeader,
  Avatar,
  MenuItem,
  ListItemIcon,
  IconButton,
  Menu,
  Divider,
  Slide,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material";

import LocalFloristOutlinedIcon from "@mui/icons-material/LocalFloristOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import DoorSlidingOutlinedIcon from "@mui/icons-material/DoorSlidingOutlined";
import LoginOutlinedIcon from "@mui/icons-material/LoginOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import DashboardCustomizeOutlinedIcon from "@mui/icons-material/DashboardCustomizeOutlined";

import { hrefLink } from "../../utils/core";
import { useTranslation } from "react-i18next";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

FrontHeader.propTypes = {
  locale: PropTypes.string,
  authenticityToken: PropTypes.string,
  currentUser: PropTypes.object,
  signedIn: PropTypes.bool,
  showLogo: PropTypes.bool
};

export default function FrontHeader({
  authenticityToken,
  locale,
  currentUser,
  signedIn,
  showLogo
}) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [logOutOpen, setLogOutOpen] = useState(false);
  const adminMenuOpen = Boolean(anchorEl);
  const handleAdminMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleAdminMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogOutOpenClose = () => {
    setLogOutOpen(!logOutOpen);
  };

  return (
    <AppBar
      position="static"
      color="default"
      elevation={0}
      sx={{
        background: "linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.01));",
        boxShadow: "none"
      }}
    >
      <Toolbar sx={{ flexWrap: "wrap" }}>
        <Box
          sx={{ flexGrow: 1 }}
          component="div"
          children={
            <CardHeader
              sx={{
                padding: 0
              }}
              disableTypography={false}
              titleTypographyProps={{
                component: "h2",
                color: "textPrimary",
                variant: "subtitle1"
              }}
              subheaderTypographyProps={{
                color: "textSecondary",
                variant: "caption"
              }}
              avatar={
                showLogo === true ? (
                  <Avatar
                    variant="square"
                    alt="Gouv"
                    src="/gouv_logo_trans.png"
                    sx={{ p: 1, width: 100, height: 100 }}
                  />
                ) : undefined
              }
              title={showLogo === false ? t("shared.projectName") : undefined}
              subheader={showLogo === false ? t("home.title") : undefined}
            />
          }
        />
        <Box>
          <Box
            component="div"
            sx={{
              flexDirection: "row",
              justifyContent: "flex-end",
              mr: 2,
              display: {
                md: "flex"
              }
            }}
          >
            <LanguageSelector locale={locale} />
          </Box>
          <Box component="div" sx={{ display: { xs: "none", md: "flex" } }}>
            <nav>
              <FrontButton
                children={t("home.menuHome")}
                href={hrefLink("/")}
                startIcon={<DoorSlidingOutlinedIcon />}
              />
              <FrontButton
                children={t("home.menuFuneral")}
                href={hrefLink("/funerals/new")}
                startIcon={<AddOutlinedIcon />}
              />
              <FrontButton
                children={t("home.menuFlowers")}
                href={hrefLink("/funerals/gifts")}
                startIcon={<LocalFloristOutlinedIcon />}
              />
            </nav>
            {currentUser !== undefined &&
              currentUser !== null &&
              signedIn === true &&
              (currentUser.is_admin === true ? (
                <React.Fragment>
                  <IconButton
                    onClick={handleAdminMenuClick}
                    size="small"
                    sx={{ my: 1, mx: 2 }}
                    aria-controls={adminMenuOpen ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={adminMenuOpen ? "true" : undefined}
                  >
                    <MenuOutlinedIcon />
                  </IconButton>

                  <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={adminMenuOpen}
                    onClose={handleAdminMenuClose}
                    onClick={handleAdminMenuClose}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1
                        },
                        "&:before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0
                        }
                      }
                    }}
                    transformOrigin={{
                      horizontal: "right",
                      vertical: "top"
                    }}
                    anchorOrigin={{
                      horizontal: "right",
                      vertical: "bottom"
                    }}
                  >
                    <MenuItem>
                      <Avatar /> {currentUser.name}
                    </MenuItem>
                    <Divider />
                    <MenuItem component="a" href={hrefLink("/admin/dashboard")}>
                      <ListItemIcon>
                        <DashboardCustomizeOutlinedIcon fontSize="small" />
                      </ListItemIcon>
                      {t("home.menuManagement")}
                    </MenuItem>
                    <MenuItem onClick={handleLogOutOpenClose}>
                      <ListItemIcon>
                        <LogoutOutlinedIcon fontSize="small" />
                      </ListItemIcon>
                      {t("home.menuSignOut")}
                    </MenuItem>
                  </Menu>
                </React.Fragment>
              ) : (
                <FrontButton
                  children={t("home.menuSignOut")}
                  sx={{ my: 1, mx: 2 }}
                  endIcon={<LogoutOutlinedIcon />}
                  onClick={handleLogOutOpenClose}
                />
              ))}
            {signedIn === false && (
              <FrontButton
                children={t("home.menuSignIn")}
                href={hrefLink("/users/sign_in")}
                sx={{ my: 1, mx: 2 }}
                startIcon={<LoginOutlinedIcon />}
              />
            )}
          </Box>
        </Box>
      </Toolbar>
      <Dialog
        open={logOutOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleLogOutOpenClose}
        aria-describedby="alert-logout-dialog-slide-description"
      >
        <DialogTitle>{t("session.signOutPrompt")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-logout-dialog-slide-description">
            {t("session.logOutDialogText")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <form
            role="form"
            acceptCharset="UTF-8"
            action={hrefLink("/users/sign_out")}
            method="post"
          >
            <input
              type="hidden"
              name="authenticity_token"
              value={authenticityToken}
            />
            <input type="hidden" name="_method" value="DELETE" />

            <FrontButton
              onClick={handleLogOutOpenClose}
              children={t("shared.noButton")}
            />
            <FrontButton type="submit" children={t("shared.yesButton")} />
          </form>
        </DialogActions>
      </Dialog>
    </AppBar>
  );
}
