import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FrontButton from '../FrontButton';
import { Box, TextField } from '@mui/material';

import { hrefLink } from '../../../utils/core';
import { useTranslation } from 'react-i18next';

EditMemoryForm.propTypes = {
  funeral: PropTypes.object,
  authenticityToken: PropTypes.string,
  handleMemoryEditOpen: PropTypes.func
};

export default function EditMemoryForm({
  funeral,
  authenticityToken,
  handleMemoryEditOpen
}) {
  const { t } = useTranslation();
  const [memory, setMemory] = useState(funeral.memory);
  return (
    <Box sx={{ flex: '1 1 auto' }}>
      <form
        role="form"
        acceptCharset="UTF-8"
        action={hrefLink('/funerals/memory_update/' + funeral.id)}
        method="post"
      >
        <input
          type="hidden"
          name="authenticity_token"
          value={authenticityToken}
        />
        <input type="hidden" name="_method" value="PATCH" />

        <input
          key={'funeral-memory'}
          type="hidden"
          name={'funeral[memory]'}
          value={memory}
        />
        <TextField
          fullWidth
          required
          id="memory"
          label={t('funeral.memory')}
          multiline
          rows={5}
          variant="filled"
          inputProps={{
            name: 'funeral[memory]'
          }}
          value={memory}
          onChange={(e) => setMemory(e.target.value)}
        />
        <FrontButton
          children={t('funeral.cancelMemoryEdit')}
          onClick={() => handleMemoryEditOpen()}
        />
        <FrontButton
          disabled={funeral.memory === memory}
          children={t('funeral.saveMemory')}
          type="submit"
        />
      </form>
    </Box>
  );
}
