import React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";

FrontButton.propTypes = {
  sx: PropTypes.object,
};

export default function FrontButton({ sx, ...other }) {
  // const propsClone = Object.assign({}, attributes);
  // delete propsClone.sx;
  return (
    <Button
      color="neutral"
      variant="outlined"
      sx={{
        m: 1,
        background: "linear-gradient(rgba(0, 0, 0, 0.1),rgba(0, 0, 0, 0.9));",
        boxShadow: "none",
        ...sx,
      }}
      {...other}
    />
  );
}
