import React from "react";
import PropTypes from "prop-types";
import FrontButton from "./FrontButton";
import { Box } from "@mui/material";

import NavigateNextOutlinedIcon from "@mui/icons-material/NavigateNextOutlined";
import NavigateBeforeOutlinedIcon from "@mui/icons-material/NavigateBeforeOutlined";
import { useTranslation } from "react-i18next";

const isInValidStep = (keys = [], obj = {}) =>
  keys.some(
    (key) => obj[key] === undefined || obj[key] === "" || obj[key] === null
  );

StepperButtons.propTypes = {
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  fields: PropTypes.array,
  values: PropTypes.object,
  steps: PropTypes.array,
  activeStep: PropTypes.number,
};

export default function StepperButtons({
  handleBack,
  handleNext,
  fields,
  values,
  steps,
  activeStep,
}) {
  const { t } = useTranslation();
  return (
    <Box sx={{ display: "flex", flexDirection: "row", pt: 4 }}>
      <FrontButton
        children={t("funeral.backButton")}
        disabled={activeStep === 0}
        onClick={handleBack}
        startIcon={<NavigateBeforeOutlinedIcon />}
      />

      <Box sx={{ flex: "1 1 auto" }} />
      <FrontButton
        disabled={isInValidStep(fields, values)}
        children={
          activeStep === steps.length - 1
            ? t("funeral.summaryButton")
            : t("funeral.nextButton")
        }
        type="button"
        onClick={handleNext}
        endIcon={
          activeStep === steps.length - 1 ? (
            <NavigateNextOutlinedIcon />
          ) : (
            <NavigateNextOutlinedIcon />
          )
        }
      />
    </Box>
  );
}
