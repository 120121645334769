import axios from "axios";
import i18n from "./i18n";

export const hrefLink = (link) => {
  // console.log(window.location);
  // origin ??
  // host ??

  if (link === "/") {
    return window.location.origin + "/" + i18n.language;
  }
  return link.startsWith("/")
    ? window.location.origin + "/" + i18n.language + link
    : window.location.origin + "/" + i18n.language + "/" + link;
};

export const langHrefLink = (lang) => {
  const pathName = window.location.pathname.slice(3);
  window.location.href = window.location.origin + "/" + lang + pathName;
};

export const isValidJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const cloudinaryUploadImage = (file, preset, url) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("upload_preset", preset);

  return axios
    .post(url, formData)
    .then((resp) => ({
      data: {
        url: resp.data.secure_url,
        public_id: resp.data.public_id,
        type: resp.data.resource_type // or "video"
      }
    }))
    .catch((error) => error);
};
