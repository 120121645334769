import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';

import UploadMultiFile from '../../shared/UploadMultiFile';

VictimPhotosForm.propTypes = {
  funeral: PropTypes.object,
  handleDropMultiFile: PropTypes.func,
  handleRemove: PropTypes.func,
  handleRemoveAll: PropTypes.func,
  t: PropTypes.func
};

export default function VictimPhotosForm({
  funeral,
  handleDropMultiFile,
  handleRemove,
  handleRemoveAll,
  t
}) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        pt: 2
      }}
    >
      <UploadMultiFile
        showPreview
        accept="image/*"
        isLoading={funeral.isUploading}
        files={funeral.funeral_pictures_attributes.map((pic) => pic.path)}
        onDrop={(acceptedFiles) =>
          handleDropMultiFile(
            acceptedFiles,
            funeral.funeral_pictures_attributes
          )
        }
        onRemove={handleRemove}
        onRemoveAll={handleRemoveAll}
        dropZoneTitle={t('funeral.picturesDropzoneArea')}
        dropZoneSubTitle={t('funeral.picturesDropzoneAreaSubTitle')}
        dropZoneRemoveAll={t('shared.fileUploaderRemoveAll')}
        dropZoneRemoveAllWarning={t('shared.fileUploaderRemoveAllPrompt')}
      />
    </Box>
  );
}
