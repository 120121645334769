import React from "react";
import { isString } from "lodash";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { motion, AnimatePresence } from "framer-motion";
// material
import { alpha, styled } from "@mui/material/styles";
import {
  Box,
  List,
  Stack,
  Paper,
  ListItem,
  Typography,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Tooltip,
  IconButton,
  CircularProgress,
} from "@mui/material";
// utils
import { fData } from "../../utils/formatNumber";
//

import { varFadeInRight } from "./animate";

// ----------------------------------------------------------------------

const DropZoneStyle = styled("div")(({ theme }) => ({
  outline: "none",
  display: "flex",
  textAlign: "center",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(5, 1),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.neutral,
  border: `1px dashed ${theme.palette.grey[500_32]}`,
  "&:hover": { opacity: 0.72, cursor: "pointer" },
  [theme.breakpoints.up("md")]: { textAlign: "left", flexDirection: "row" },
}));

// ----------------------------------------------------------------------

UploadMultiFile.propTypes = {
  error: PropTypes.bool,
  showPreview: PropTypes.bool,
  files: PropTypes.array,
  onRemove: PropTypes.func,
  onRemoveAll: PropTypes.func,
  sx: PropTypes.object,
  isLoading: PropTypes.bool,
  dropZoneTitle: PropTypes.string,
  dropZoneSubTitle: PropTypes.string,
  dropZoneRemoveAll: PropTypes.string,
  dropZoneRemoveAllWarning: PropTypes.string,
};

export default function UploadMultiFile({
  error,
  showPreview = false,
  files,
  onRemove,
  onRemoveAll,
  sx,
  isLoading,
  dropZoneTitle,
  dropZoneSubTitle,
  dropZoneRemoveAll,
  dropZoneRemoveAllWarning,
  ...other
}) {
  const hasFile = files.length > 0;

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    fileRejections,
  } = useDropzone({
    ...other,
  });

  const ShowRejectionItems = () => (
    <Paper
      variant="outlined"
      sx={{
        py: 1,
        px: 2,
        mt: 3,
        borderColor: "error.light",
        bgcolor: (theme) => alpha(theme.palette.error.main, 0.08),
      }}
    >
      {fileRejections.map(({ file, errors }) => {
        const { path, size } = file;
        return (
          <Box key={path} sx={{ my: 1 }}>
            <Typography variant="subtitle2" noWrap>
              {path} - {fData(size)}
            </Typography>
            {errors.map((e) => (
              <Typography key={e.code} variant="caption" component="p">
                - {e.message}
              </Typography>
            ))}
          </Box>
        );
      })}
    </Paper>
  );

  return (
    <Box
      sx={{
        textAlign: "center",
        width: "100%",
        p: 2,
        border: "1px dashed grey",
        ...sx,
      }}
    >
      <DropZoneStyle
        {...getRootProps()}
        sx={{
          ...(isDragActive && { opacity: 0.72 }),
          ...((isDragReject || error) && {
            color: "error.main",
            borderColor: "error.light",
            bgcolor: "error.lighter",
          }),
        }}
      >
        <input {...getInputProps()} />
        <Box sx={{ textAlign: "center", p: 3, ml: { md: 2 } }}>
          <Typography gutterBottom variant="h5">
            {dropZoneTitle}
          </Typography>

          <Typography
            variant="caption"
            sx={{ color: "primary.main", textDecoration: "underline" }}
          >
            {dropZoneSubTitle}
          </Typography>
        </Box>
      </DropZoneStyle>
      {fileRejections.length > 0 && <ShowRejectionItems />}
      {hasFile && (
        <Stack direction="row" justifyContent="flex-end">
          <Tooltip title={dropZoneRemoveAll}>
            <IconButton
              onClick={() =>
                window.confirm(dropZoneRemoveAllWarning) && onRemoveAll()
              }
            >
              <CloseOutlinedIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      )}
      {isLoading && <CircularProgress />}
      <List disablePadding sx={{ ...(hasFile && { my: 3 }) }}>
        <AnimatePresence>
          {files.map((file) => {
            const { name, size, preview } = file;
            const key = isString(file) ? file : name;

            if (showPreview) {
              return (
                <ListItem
                  key={key}
                  component={motion.div}
                  {...varFadeInRight}
                  sx={{
                    p: 0,
                    m: 0.5,
                    width: 140,
                    height: 140,
                    borderRadius: 1.5,
                    overflow: "hidden",
                    position: "relative",
                    display: "inline-flex",
                  }}
                >
                  <Paper
                    variant="outlined"
                    component="img"
                    src={isString(file) ? file : preview}
                    sx={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      position: "absolute",
                    }}
                  />
                  <Box sx={{ top: 6, right: 6, position: "absolute" }}>
                    <IconButton
                      size="small"
                      onClick={() => onRemove(file)}
                      sx={{
                        p: "2px",
                        color: "common.white",
                        bgcolor: (theme) =>
                          alpha(theme.palette.grey[900], 0.72),
                        "&:hover": {
                          bgcolor: (theme) =>
                            alpha(theme.palette.grey[900], 0.48),
                        },
                      }}
                    >
                      <CloseOutlinedIcon />
                    </IconButton>
                  </Box>
                </ListItem>
              );
            }

            return (
              <ListItem
                key={key}
                component={motion.div}
                {...varFadeInRight}
                sx={{
                  my: 1,
                  py: 0.75,
                  px: 2,
                  borderRadius: 1,
                  border: (theme) => `solid 1px ${theme.palette.divider}`,
                  bgcolor: "background.paper",
                }}
              >
                <ListItemIcon>
                  <CloseOutlinedIcon />
                </ListItemIcon>
                <ListItemText
                  primary={isString(file) ? file : name}
                  secondary={isString(file) ? "" : fData(size)}
                  primaryTypographyProps={{ variant: "subtitle2" }}
                  secondaryTypographyProps={{ variant: "caption" }}
                />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    size="small"
                    onClick={() => onRemove(file)}
                    component="span"
                  >
                    <CloseOutlinedIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </AnimatePresence>
      </List>
    </Box>
  );
}
