import React from "react";
import PropTypes from "prop-types";
import { Typography, Box, Link } from "@mui/material";
import LanguageSelector from "./LanguageSelector";
import { useTranslation } from "react-i18next";

FrontFooterGovSign.propTypes = {
  showLanguageSelector: PropTypes.bool,
  locale: PropTypes.string,
};

export default function FrontFooterGovSign({ showLanguageSelector, locale }) {
  const { t } = useTranslation();
  return (
    <>
      <Typography
        variant="caption"
        component="div"
        color="textSecondary"
        align="center"
      >
        {"Copyright © "}
        <Link
          target="_blank"
          color="inherit"
          href="https://gouvrwandaexil.org/"
        >
          {t("shared.copyright")}
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
      {showLanguageSelector && (
        <Box
          component="div"
          sx={{
            flexDirection: "row",
            justifyContent: "center",
            mt: 1,
            display: {
              md: "flex",
            },
          }}
        >
          <LanguageSelector locale={locale} />
        </Box>
      )}
    </>
  );
}
