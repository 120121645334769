import React, { useState, forwardRef } from "react";
import PropTypes from "prop-types";
import { capitalize } from "lodash";
import { AlertTitle, Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";

Flash.propTypes = {
  flash: PropTypes.array,
  errors: PropTypes.array,
};

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} {...props} />;
});

export default function Flash({ flash, errors }) {
  const [open, setOpen] = useState(true);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <React.Fragment>
      {flash.map((flash) => (
        <Snackbar
          key={"flash-" + flash[0]}
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            variant="filled"
            severity={flash[0] === "alert" ? "error" : "success"}
            sx={{ width: "100%" }}
            onClose={handleClose}
          >
            {flash[1]}
          </Alert>
        </Snackbar>
      ))}
      {errors !== undefined &&
        errors !== null &&
        Object.keys(errors).map((errorKey) => (
          <Snackbar
            key={"error-" + errorKey}
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              variant="filled"
              severity={"error"}
              sx={{ width: "100%" }}
              onClose={handleClose}
            >
              <AlertTitle>{capitalize(errorKey)}</AlertTitle>
              {errors[errorKey]}
            </Alert>
          </Snackbar>
        ))}
    </React.Fragment>
  );
}
